<template>
  <div class="responsive">
    <pre>responsive</pre>
    <ul>
      <li>
        Breakpoint: <code>`{{ bp }}`</code>
      </li>
      <li>
        Was initially smaller than <code>`s`</code>: {{ isSmaller }} (static)
      </li>
      <li :class="{ ok: smaller }">
        Is smaller than <code>`s`</code>: {{ smaller }} (static:
        {{ isSmaller }})
      </li>
      <li :class="{ ok: between }">
        Is between than <code>`s`</code> and <code>`m`</code>: {{ between }}
      </li>
      <li :class="{ ok: greater.status }">
        Is greater than <code>`m`</code>: {{ greater.status }}
        <small>[{{ count }}]</small>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { useResponsive } from '@/utils/aware'

const count = ref(-1)

// FEATURE: responsive
const bp = ref('')
const isSmaller = useResponsive('isSmaller', 's')
const smaller = useResponsive('smaller', 's')
const between = useResponsive('between', ['s', 'm'])

useResponsive('smaller', 's', value => {
  if (value) {
    bp.value = 'xs'
  } else {
    bp.value = ''
  }
})
useResponsive('between', ['s', 'm'], value => {
  if (value) {
    bp.value = 's'
  }
})
const greater = useResponsive(
  'greater',
  'm',
  value => {
    if (value) {
      bp.value = 'm'
    }
    count.value += 1
  },
  { immediate: true }
)
</script>

<style lang="scss" scoped>
.responsive,
[class*='responsive--'] {
  .ok {
    color: $c-dev-success;
  }
}
</style>
