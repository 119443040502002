<template>
  <div class="simulator-container wrapper pt-l pb-xxxl bg-cobalt">
    <h2 class="simulator__title h3 mb-l">
      {{ t('simulateur.calculez-votre-economie') }}
    </h2>

    <div class="simulator__inputs">
      <SimulatorInput
        :content="{
          ...store.values.employeeNumber,
          value: store.employeeNumber,
        }"
        class="simulator__input"
        @input="onEmployeeNumberInput"
      />

      <SimulatorInput
        :content="{ ...store.values.faceValue, value: store.faceValue }"
        class="simulator__input"
        @input="onFaceValueInput"
      />
    </div>

    <div class="simulator__results">
      <SimulatorAmounts
        :content="{
          title: t('simulateur.employeur'),
          subtitle: t('simulateur.cout-mensuel-brut'),
          items: [
            {
              label: `<small>${t('simulateur.en-salaire')}</small>`,
              value: store.formatPrice(store.netAugmentation),
            },
            {
              label: `<small>${t('simulateur.avec-ticket-restaurant')}</small>`,
              value: store.formatPrice(store.mealTicketCost),
            },
            {
              label: `<small>${t('simulateur.economie-mensuel')}</small>`,
              value: store.formatPrice(store.netMonthlySavings),
            },
          ],
        }"
        class="simulator__results__item"
      />

      <SimulatorAmounts
        :content="{
          title: t('simulateur.beneficiaire'),
          subtitle: t('simulateur.montant-attribue'),
          items: [
            {
              label: `<small>${t('simulateur.valeur-brut')}</small>`,
              value: store.formatPrice(store.salary),
            },
            {
              label: `<small>${t('simulateur.quote-part')}</small>`,
              value: store.formatPrice(store.quotePart),
            },
            {
              label: `<small>${t('simulateur.montant-net')}</small>`,
              value: store.formatPrice(store.netPerWorker),
            },
          ],
        }"
        class="simulator__results__item"
      />

      <div class="simulator__results__footer">
        <p class="simulator__results__footer__content">
          <strong>
            {{ t('simulateur.soit') }}
            {{ store.formatPercent(store.netMonthlySavingsPercent) }}
            {{ t('simulateur.moins-cher') }}
          </strong>
          <small>{{
            t('simulateur.par-rapport-à-une-augmentation-de-salaire')
          }}</small>

          <a href="#details" @click="onDetailsClick">{{
            t('simulateur.details-du-calcul')
          }}</a>
        </p>

        <Action
          :content="{
            ...(resource.content as unknown as SimulatorPage).order_link,
            modifiers: ['btn'],
          }"
          class="simulator__results__footer__action"
          @click="onOrderClick"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import SimulatorAmounts from '@/components/simulator/Amounts.vue'
import SimulatorInput from '@/components/simulator/Input.vue'
import { useResourceStore } from '@/stores/resource'
import { useSimulatorStore } from '@/stores/simulator'
import { push } from '@/utils/tracking'

import type { SimulatorPage } from '@/views/pages/Simulator.vue'

const store = useSimulatorStore()
const resource = useResourceStore()
const { t } = useI18n()

// Emit
const emit = defineEmits<{
  'details-show': []
}>()

// Update simulator values
const onEmployeeNumberInput = (value: number) => (store.employeeNumber = value)
const onFaceValueInput = (value: number) => (store.faceValue = value)

// Tell parent to show details
const onDetailsClick = (e: MouseEvent) => {
  e.preventDefault()
  emit('details-show')

  // Track click
  push({
    event: 'simulator',
    eventCategory: 'simulator',
    eventAction: 'click',
    eventLabel: 'detail',
  })
}

// Track click on order CTA
const onOrderClick = () =>
  push({
    event: 'simulator',
    eventCategory: 'simulator',
    eventAction: 'click',
    eventLabel: 'order',
  })
</script>

<style lang="scss" scoped>
$bp-flex: l;
$bp-large: wrapper;

.simulator-container {
  @include mq($bp-flex) {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
}

.simulator__title {
  @extend %fw-bold;

  width: 100%;
}

// Inputs
.simulator__inputs {
  padding: $spacing * 1.5 $spacing * 0.25;
  background: var(--c-accent-40);
  border: 0.1rem solid var(--c-accent);
  border-radius: 2rem;

  @include mq(xs) {
    padding: $spacing * 1.5;
  }

  @include mq($bp-flex) {
    width: col(7, var(--available-columns));
    padding: $spacing * 3 col(0.5, var(--available-columns));
  }

  @include mq(xl) {
    padding: $spacing * 4 col(1, var(--available-columns));
  }

  @include mq($bp-large) {
    width: col(5, var(--available-columns));
  }
}

.simulator__input {
  & + & {
    margin-top: $spacing;
  }

  @include mq($bp-flex) {
    & + & {
      margin-top: $spacing * 3;
    }
  }
}

// Results
.simulator__results {
  margin-top: $spacing;
  border: 0.1rem solid var(--c-accent);
  border-radius: 1.5rem;

  @include mq($bp-flex) {
    display: flex;
    flex-wrap: wrap;
    width: col(10.5, var(--available-columns));
    margin: 0 0 0 col(0.5, var(--available-columns));
  }

  @include mq($bp-large) {
    flex: 1;
  }
}

.simulator__results__item {
  & + & {
    border-top: 0.1rem solid var(--c-accent-20);
  }

  @include mq($bp-flex) {
    flex: 1;

    & + & {
      border-top: 0;
    }

    :deep(.simulator-amounts__list) {
      padding-bottom: 0;
    }
  }
}

.simulator__results__footer {
  padding: $spacing;
  background: $c-secondary;
  border-top: 0.1rem solid var(--c-accent-20);
  border-radius: 0 0 1.5rem 1.5rem;

  @include mq($bp-flex) {
    width: 100%;
    padding: $spacing * 1.5 col(0.5, 10.5);
  }

  @include mq($bp-large) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: col(1, 12);
  }
}

.simulator__results__footer__content {
  strong,
  a {
    display: block;
  }

  a {
    margin-top: $spacing * 0.5;
    color: var(--c-accent);
    text-decoration: underline;
    transition: color 0.1s;

    &:hover,
    &:focus-visible {
      color: var(--c-accent-40);
    }
  }
}

.simulator__results__footer__action {
  margin-top: $spacing;
}
</style>
