<template>
  <section ref="rootEl" class="keyfacts-product-lu pt-xxxl">
    <div class="keyfacts-product-lu-inner wrapper">
      <h2
        ref="titleEl"
        class="keyfacts-product-lu__title mb-xl"
        v-html="content.title"
      ></h2>

      <div
        v-if="content.subtitle"
        ref="textEl"
        class="keyfacts-product-lu__text lead-paragraph"
        v-html="content.subtitle"
      ></div>

      <ul class="keyfacts-product-lu__list">
        <li ref="item" class="keyfacts-product-lu__item bg-violet">
          <h3 class="keyfacts-product-lu__item__title h4">
            <SvgSprite
              class="keyfacts-product-lu__item__icon"
              symbol="keyfacts-medaille"
              size="48"
              aria-hidden="true"
            />
            {{ content.facts.title }}
          </h3>
          <ul class="keyfacts-product-lu__item__amounts">
            <li
              v-for="item in content.facts.items"
              :key="item.amount"
              class="keyfacts-product-lu__item__amounts__item"
            >
              <KeyfactsAmount :content="item" />
            </li>
          </ul>
        </li>
        <li ref="item" class="keyfacts-product-lu__item bg-mint">
          <h3 class="keyfacts-product-lu__item__title h4">
            <SvgSprite
              class="keyfacts-product-lu__item__icon"
              symbol="keyfacts-cadeau-coeur"
              size="48"
              aria-hidden="true"
            />
            {{ content.info.item_1.text }}
          </h3>
          <div
            class="keyfacts-product-lu__item__separator"
            v-html="content.info.separator"
          ></div>
          <h3 class="keyfacts-product-lu__item__title h4">
            <SvgSprite
              class="keyfacts-product-lu__item__icon"
              symbol="keyfacts-gateau-anniversaire"
              size="48"
              aria-hidden="true"
            />
            {{ content.info.item_2?.text }}
          </h3>
          <KeyfactsAmount
            :content="{
              amount: content.info.amount,
              label: content.info.label,
            }"
            class="keyfacts-product-lu__item__amount"
          />
        </li>
      </ul>
    </div>

    <KeyfactsShared
      v-if="content.pictogramItems?.length || content.cta1"
      :content="{
        items: content.pictogramItems,
        ctas: [content.cta1, content.cta2],
      }"
      color="cobalt"
      class="keyfacts-product-lu__shared"
    />
  </section>
</template>

<script lang="ts">
import KeyfactsAmount from '@/components/keyfacts/Amount.vue'

import type { SvgSprite } from 'vue-svg-sprite'
import type { KeyfactsAmount as Amount } from '@/components/keyfacts/Amount.vue'
import type { KeyfactsSharedItem } from '@/components/keyfacts/Shared.vue'
import type { Link, Picture } from '@/types'

export interface KeyfactsProduct {
  title: string
  subtitle?: string
  facts: {
    title: string
    text: string
    background: string
    picture: Picture
    items: Amount[]
  }
  info: {
    item_1: {
      picture: Picture
      text: string
    }
    separator: string
    item_2?: {
      picture: Picture
      text: string
    }
    amount: string
    label: string
  }
  pictogramItems: KeyfactsSharedItem[]
  cta1?: Link
  cta2?: Link
}
</script>

<script setup lang="ts">
import gsap from 'gsap'
import { onMounted, ref } from 'vue'

import KeyfactsShared from '@/components/keyfacts/Shared.vue'
import { onAppear } from '@/utils/motion'

import type { PropType } from 'vue'

defineProps({
  content: {
    type: Object as PropType<KeyfactsProduct>,
    required: true,
  },
})

// Refs
const rootEl = ref<HTMLElement | null>(null)
const titleEl = ref<HTMLElement | null>(null)
const listEl = ref<HTMLElement | null>(null)
const itemsEl = ref<HTMLElement[]>([])

// Create appear animation
const appear = () => {
  if (!rootEl.value) {
    return
  }

  const animatedEls: Element[] = [rootEl.value]
  const isMobile = window.innerWidth < 768
  const tl = gsap.timeline({
    onComplete: () => {
      gsap.set(animatedEls, { clearProps: 'all' })
    },
  })

  tl.set(rootEl.value, { clearProps: 'opacity' })

  tl.set(listEl.value, {
    opacity: 0,
  })
    .set(titleEl.value, {
      opacity: 0,
      y: 10,
    })
    .set(itemsEl.value, {
      opacity: 0,
      y: 20,
      scaleX: isMobile ? 0 : 1,
      scaleY: isMobile ? 1 : 0,
    })

  tl.add('start')
    .to(
      titleEl.value,
      {
        opacity: 1,
        y: 0,
        ease: 'Power4.easeOut',
        duration: 0.35,
      },
      'start'
    )
    .to(
      listEl.value,
      {
        opacity: 1,
        ease: 'Power4.easeOut',
        duration: 0.35,
      },
      'start'
    )
    .add('visible', 'start+=0.2')
    .to(itemsEl.value, {
      scaleY: 1,
      scaleX: 1,
      ease: 'Power4.easeOut',
      duration: 0.4,
      stagger: 0.15,
    })
    .to(itemsEl.value, {
      opacity: 1,
      y: 0,
      duration: 0.35,
      stagger: 0.15,
    })
}

onMounted(() => {
  if (!rootEl.value) {
    return
  }

  gsap.set(rootEl.value, { opacity: 0 })

  // Animate on appear
  onAppear(rootEl.value, () => {
    appear()
  })
})
</script>

<style lang="scss" scoped>
$bp: nav;

.keyfacts-product-lu {
  overflow: hidden;
}

.keyfacts-product-lu-inner {
  @include mq($until: s) {
    padding: 0 0 $spacing * 3 0;
  }
}

.keyfacts-product-lu__title {
  @include section-title;

  padding-right: col(1, var(--available-columns));
  padding-left: col(1, var(--available-columns));

  @include mq($bp) {
    max-width: none;
    padding-right: col(2, var(--available-columns));
    padding-left: col(2, var(--available-columns));
    text-align: center;
  }
}

.keyfacts-product-lu__list {
  @extend %list-nostyle;

  position: relative;

  @include mq(s) {
    display: flex;
    justify-content: space-between;
  }

  @include mq(xl) {
    width: auto;
    margin-right: col(2, var(--available-columns));
    margin-left: col(2, var(--available-columns));
  }
}

.keyfacts-product-lu__item {
  margin: $spacing * 0.5 col(1) 0 0;
  padding: $spacing * 2 col(3) $spacing * 2 col(1);
  text-align: left;
  background: var(--c-accent-bg);
  border: 0.1rem solid var(--c-accent-border);
  border-left: 0;
  border-radius: 0 60rem 60rem 0;
  transform-origin: left bottom;

  & + & {
    margin-top: $spacing * 4;

    &::before {
      @extend %fw-bold;
      @include center-x;

      content: '';
      display: block;
      width: 4rem;
      height: 4rem;
      background: url('@/assets/svg/ui/plus-bold.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      transform: translate(-50%, calc(-100% - 6rem));
    }
  }

  @include mq(xs) {
    padding: $spacing * 2 col(2) $spacing * 2;
  }

  @include mq(s) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: col(8, var(--available-columns));
    margin: 0;
    padding: $spacing * 4 $spacing;
    text-align: center;
    border-bottom: 0;
    border-left: 0.1rem solid var(--c-accent-border);
    border-radius: 80rem 80rem 0 0;

    & + & {
      margin-top: 0;

      &::before {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  @include mq(wrapper) {
    width: col(7, var(--available-columns));
  }
}

.keyfacts-product-lu__item__title {
  @extend %fw-bold;

  @include mq(s) {
    max-width: 14em;
  }
}

.keyfacts-product-lu__item__icon {
  display: block;
  flex-shrink: 0;
  width: 4rem;
  height: 4rem;
  margin-bottom: $spacing * 0.75;
  fill: var(--c-accent);

  @include mq(s) {
    margin: 0 auto $spacing;
  }
}

.keyfacts-product-lu__item__amounts {
  @extend %list-nostyle;
}

.keyfacts-product-lu__item__amounts__item {
  margin-top: $spacing * 2;

  &:last-child {
    margin-bottom: $spacing;
  }

  @include mq(s) {
    width: fit-content;
    margin: $spacing * 2 auto 0;
  }
}

.keyfacts-product-lu__item__separator {
  @extend %h4;
  @extend %fw-medium;
  @extend %text-uppercase;

  width: fit-content;
  margin: $spacing 0;
  padding: $spacing * 0.5;
  background: var(--c-accent-light);
  border-radius: 50%;

  @include mq(l) {
    margin: $spacing * 2 auto;
  }
}

.keyfacts-product-lu__item__amount {
  margin-top: $spacing;
}
</style>
