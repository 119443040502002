<template>
  <div class="resize">
    <pre>resize</pre>
    <div ref="box" class="box">BOX</div>
    <ul>
      <li>
        <strong>Window resized: {{ windowResize.count }}</strong> --- (w:
        {{ windowResize.width }} / h: {{ windowResize.height }} -> r:
        {{ windowResize.ratio }}
      </li>
      <li>
        <strong>Box resized: {{ boxResize.count }}</strong> --- (w:
        {{ boxResize.width }} / h: {{ boxResize.height }} -> r:
        {{ boxResize.ratio }})
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { reactive, ref } from 'vue'

import { useResize } from '@/utils/aware'

const windowResize = reactive({
  count: -1,
  width: 0,
  height: 0,
  ratio: 0,
})
const boxResize = reactive({
  count: -1,
  width: 0,
  height: 0,
  ratio: 0,
})

// FEATURE: appear
const box = ref(null)

useResize(({ width, height, ratio }) => {
  windowResize.count += 1
  windowResize.width = width
  windowResize.height = height
  windowResize.ratio = ratio
})
useResize(box, ({ width, height, ratio }) => {
  boxResize.count += 1
  boxResize.width = width
  boxResize.height = height
  boxResize.ratio = ratio
})
</script>

<style lang="scss" scoped>
.resize,
[class*='resize--'] {
  .box {
    max-width: 60rem;
    padding: 1rem;
    border: 1px solid $c-main;
  }
}
</style>
