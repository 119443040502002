<template>
  <div class="solution">
    <FocusBasic
      :content="{
        background_color: content.background_color,
        headline: content.focus?.headline,
        text: content.baseline,
        hide_logo: content.hide_logo,
        links: [content.header_link1],
        title: content.title,
        picture: content.focus?.picture,
      }"
      @modal-trigger="onModalTrigger"
    />

    <SolutionSticky
      :content="{
        link: content.menu_sticky_link,
        logo: content.logo,
        universe: content.focus?.headline,
        hide_logo: content.hide_logo,
        slug: content.slug,
      }"
    />

    <SolutionModal v-if="content.three_way" :content="content.three_way" />

    <GPlayer
      v-if="content.video?.url"
      :content="content.video"
      class="wrapper"
    />

    <DuoTextCpt
      v-if="content.intro_text_duo"
      :content="content.intro_text_duo"
    />

    <KeyfactsProductLu
      v-if="content.keyfacts_product"
      :content="content.keyfacts_product"
    />

    <DuoCombo v-if="content.combo" :content="content.combo" />

    <TipsCpt v-if="content.tips" :content="content.tips" />

    <CtaPictureRight
      v-if="content.last_chance"
      :content="content.last_chance"
    />

    <GridSolutions
      v-if="content.solutions_grid"
      :content="content.solutions_grid"
    />

    <GridNews v-if="content.news_grid" :content="content.news_grid" />

    <CtaRibbon v-if="content.ctaRibbon" :content="content.ctaRibbon" />
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'

import CtaPictureRight from '@/components/cta/PictureRight.vue'
import CtaRibbon from '@/components/cta/Ribbon.vue'
import DuoCombo from '@/components/duo/Combo.vue'
import DuoTextCpt from '@/components/duo/Text.vue'
import FocusBasic from '@/components/focus/Basic.vue'
import GridNews from '@/components/grid/News.vue'
import GridSolutions from '@/components/grid/Solutions.vue'
import KeyfactsProductLu from '@/components/keyfacts/ProductLu.vue'
import SolutionModal from '@/components/solution/Modal.vue'
import SolutionSticky from '@/components/solution/Sticky.vue'
import TipsCpt from '@/components/ui/Tips.vue'
import { useUiStore } from '@/stores/ui'

import type { PropType } from 'vue'
import type { CtaPictureRight as CtaPictureRightType } from '@/components/cta/PictureRight.vue'
import type { RibbonCta } from '@/components/cta/Ribbon.vue'
import type { DuoCombo as DuoComboType } from '@/components/duo/Combo.vue'
import type { DuoText } from '@/components/duo/Text.vue'
import type { FocusBasic as FocusBasicType } from '@/components/focus/Basic.vue'
import type { NewsGrid } from '@/components/grid/News.vue'
import type { SolutionGrid } from '@/components/grid/Solutions.vue'
import type { KeyfactsProduct as KeyfactsProductType } from '@/components/keyfacts/ProductLu.vue'
import type { SolutionModal as SolutionModalType } from '@/components/solution/Modal.vue'
import type { Sticky } from '@/components/solution/Sticky.vue'
import type { Tips } from '@/components/ui/Tips.vue'
import type { Link, Picture } from '@/types'

interface SolutionLu {
  background_color: string
  baseline: string
  combo: DuoComboType
  ctaRibbon: RibbonCta
  focus: FocusBasicType
  header_link1: Link
  header_link2?: Link
  hide_logo: boolean
  illustration_picture: Picture
  intro_text_duo: DuoText
  keyfacts_product: KeyfactsProductType
  last_chance?: CtaPictureRightType
  logo: Sticky['logo']
  menu_sticky_link: Link
  news_grid: NewsGrid
  slug: string
  solutions_grid: SolutionGrid
  sticky?: Sticky
  three_way: SolutionModalType
  tips: Tips
  title: string
  video?: {
    url: string
  }
}

defineProps({
  content: {
    type: Object as PropType<SolutionLu>,
    required: true,
  },
})

const ui = useUiStore()
const route = useRoute()

const onModalTrigger = () => ui.toggleModal(true)

onMounted(() => {
  // Show order modal
  if (route.hash === '#order') {
    ui.toggleModal(true)
  }
})
</script>
