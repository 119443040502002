<template>
  <div class="viewport">
    <pre>viewport</pre>
    <ul>
      <li>{{ width }}px / {{ height }}px = {{ ratio }}</li>
      <li>Refreshed: {{ count }}</li>
      <li>
        <code>var(--vh): {{ vh }};</code>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'

import { useViewport } from '@/utils/aware'

const count = ref(0)
const vh = ref('…px')

// FEATURE: viewport
const { width, height, ratio } = useViewport()
const update = () => {
  count.value += 1
  if (!import.meta.env.SSR) {
    vh.value = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue('--vh')
  }
}

update()
watch(height, update)
</script>

<style lang="scss" scoped>
.viewport,
[class*='viewport--'] {
  .ok {
    color: $c-dev-success;
  }
}
</style>
