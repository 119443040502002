<template>
  <template v-for="(item, i) in items" :key="`${prefix}-${i}`">
    <div
      :id="`${prefix}-reed-${getIndex(i)}`"
      :class="{ 'is-active': isActive(i) }"
      class="reed"
      :aria-controls="`${prefix}-bellows-${getIndex(i)}`"
      :aria-expanded="isActive(i)"
    >
      <button class="trigger" @click="onClick(i)">
        <slot name="title" :item="item">
          {{ item.title }}
        </slot>
      </button>
      <slot name="icon" :item="item">
        <SvgSprite symbol="ui-arrow" size="12" class="icon" />
      </slot>
    </div>
    <div
      :id="`${prefix}-bellows-${getIndex(i)}`"
      role="region"
      class="bellows"
      :aria-labelledby="`${prefix}-reed-${getIndex(i)}`"
      :hidden="!isActive(i)"
    >
      <slot name="content" :item="item"></slot>
    </div>
  </template>
</template>

<script lang="ts">
export default {
  name: 'Accordion',
}
</script>

<script setup lang="ts">
import { nanoid } from 'nanoid'
import { computed, ref } from 'vue'

import type { PropType } from 'vue'

const emit = defineEmits(['toggle'])
const props = defineProps({
  items: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    type: Array as PropType<any[]>,
    default: () => [1],
  },
  active: {
    type: Number,
    default: -1,
  },
  index: {
    type: Number,
    default: undefined,
  },
  isOpen: {
    type: Boolean,
    default: undefined,
  },
})

const active = ref(props.active)
const getIndex = (i: number) => (props.index === undefined ? i : props.index)
const isActive = computed(
  () => (i: number) =>
    props.isOpen === undefined ? active.value === getIndex(i) : props.isOpen
)
const prefix = `accordion-${nanoid()}`

const onClick = (i: number) => {
  const index = getIndex(i)

  if (props.isOpen === undefined) {
    if (active.value === index) {
      active.value = -1
    } else {
      active.value = index
    }
  }

  emit('toggle', index)
}
</script>

<style lang="scss" scoped>
.reed,
.bellows {
  margin-bottom: -1px;
  border: 1px solid $c-gray-light;
}

.trigger,
.bellows {
  padding: 1em;
}

.reed {
  display: flex;
  align-items: center;
  transition: background-color 0.2s ease-in-out;

  &.is-active {
    background-color: $c-gray-lightest;
  }
}

.trigger {
  flex-grow: 1;
  padding: 1em;
  text-align: left;
  background: none;
  border: 0;
}

.icon {
  margin-right: 1.5em;
  transition: transform 0.2s ease-in-out;
  fill: $c-gray-light;

  .is-active > & {
    transform: rotate(180deg);
  }
}
</style>
