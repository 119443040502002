<template>
  <section id="container" class="release-grid p-xxxl">
    <div class="wrapper">
      <h2
        v-if="content.title"
        id="title"
        class="release-grid__title"
        :class="`title-${content.titleColor}`"
        v-html="content.title"
      ></h2>

      <ul id="list" class="release-grid__cards">
        <li
          v-for="(card, index) in content.cards"
          :key="index"
          class="release-grid__card"
        >
          <div class="release-grid__card__picture">
            <img
              v-if="card.picture"
              :src="card.picture.src"
              :width="card.picture.width"
              :height="card.picture.height"
              :srcset="card.picture.srcset"
              :alt="card.picture.alt"
              sizes="(min-width: 1024px) 50vw, 100vw"
            />
          </div>
          <div class="release-grid__card__content">
            <time class="release-grid__card__content__date">
              {{ formatDate(card.date.value) }}
            </time>
            <h3
              class="release-grid__card__content__title"
              v-html="card.title"
            ></h3>
          </div>

          <a class="release-grid__card__link" :href="card.url">
            <span class="h-visually-hidden">
              {{ t('global.en-savoir-plus') }}
            </span>
          </a>
        </li>
      </ul>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import { useChromeStore } from '@/stores/chrome'

import type { PropType } from 'vue'
import type { Picture } from '@/types'

const { t } = useI18n()

const chrome = useChromeStore()

interface Release {
  title: string
  titleColor?: string
  cards: {
    picture: Picture
    title: string
    date: {
      value: string
    }
    url: string
  }[]
}

defineProps({
  content: {
    type: Object as PropType<Release>,
    required: true,
    default: () => ({
      title: 'Nos derniers <strong>communiqués de presse</strong>',
      cards: [
        {
          picture: {
            // eslint-disable-next-line max-len
            src: 'https://images.unsplash.com/photo-1713283699002-ac9462cedf0a?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw0fHx8ZW58MHx8fHx8',
          },
          url: '/fr/test',
          date: {
            value: '29 novembre 2023',
          },
          title:
            // eslint-disable-next-line max-len
            'Les chèques repas Edenred peuvent désormais être utilisés pour régler les pizzas Domino’s livrées à domicile.',
        },
        {
          picture: {
            // eslint-disable-next-line max-len
            src: 'https://images.unsplash.com/photo-1713283699002-ac9462cedf0a?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw0fHx8ZW58MHx8fHx8',
          },
          url: '/fr/test',
          date: {
            value: '29 novembre 2023',
          },
          title:
            // eslint-disable-next-line max-len
            '<strong>Edenred modifie le nom de ses solutions : </strong>la Belgique premier pays au monde à adopter ces nouvelles dénominations',
        },
        {
          picture: {
            // eslint-disable-next-line max-len
            src: 'https://images.unsplash.com/photo-1713283699002-ac9462cedf0a?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw0fHx8ZW58MHx8fHx8',
          },
          url: '/fr/test',
          date: {
            value: '29 novembre 2023',
          },
          title:
            // eslint-disable-next-line max-len
            '<strong>Edenred modifie le nom de ses solutions : </strong>la Belgique premier pays au monde à adopter ces nouvelles dénominations',
        },
      ],
    }),
  },
})

const formatDate = (dateString: string): string =>
  new Date(dateString).toLocaleDateString(chrome.language, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  })
</script>

<style lang="scss" scoped>
.release-grid__title {
  @extend %text-center;
  @include section-title;

  width: 50%;
  margin: auto;
  margin-bottom: $spacing * 2.5;

  @include mq(l) {
    margin-bottom: $spacing * 4;
  }
}

.release-grid__cards {
  --v-gap: #{$spacing * 3.5};
  --h-gap: #{$spacing * 5};

  @extend %list-nostyle;

  @include mq(l) {
    display: flex;
    flex-wrap: wrap;
    gap: var(--v-gap) var(--h-gap);
  }
}

.release-grid__card {
  position: relative;
  display: flex;
  gap: $spacing * 0.75;

  &:first-child {
    flex-direction: column;
    margin-bottom: $spacing * 2;
  }

  & + & {
    margin-top: $spacing * 1.5;
  }

  @include mq(l) {
    flex-direction: column;
    flex: 1;

    &:first-child {
      flex-direction: row;
      flex-basis: 100%;
      gap: var(--h-gap);
      margin: 0;
    }

    & + & {
      margin-top: 0;
    }
  }
}

.release-grid__card__picture {
  position: relative;
  overflow: hidden;
  width: 35%;
  aspect-ratio: 16 / 9;
  border-radius: 1rem;

  li:first-child & {
    width: 100%;
  }

  img {
    @include image-fit;

    transition: transform 0.3s ease-out;
  }

  @include mq(l) {
    width: 100%;
    border-radius: 2rem;

    li:first-child & {
      flex: 1;
    }
  }

  @media (pointer: fine) {
    li:hover & {
      img {
        transform: scale(1.1);
      }
    }
  }
}

.release-grid__card__content {
  flex: 1;

  @include mq(l) {
    li:first-child & {
      flex: auto 0 0;
      width: calc(
        calc(100% - calc(var(--h-gap) * 2)) / 3
      ); // same width as bottom cards
    }
  }
}

.release-grid__card__content__date {
  @extend %text-uppercase;
  @extend %fw-semi;

  display: block;
  font-size: 1.1rem;
  letter-spacing: 1px;
}

.release-grid__card__content__title {
  margin-top: $spacing * 0.5;
  font-family: $ff-default;
  font-size: 1.4rem;
  line-height: 1.7;

  li:first-child & {
    font-size: 1.8rem;
  }

  @include mq(l) {
    font-size: 1.8rem;

    li:first-child & {
      margin-top: $spacing * 1.2;
      font-size: 2.4rem;
    }
  }
}

.release-grid__card__link {
  @include get-all-space;
}
</style>
