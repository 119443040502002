<template>
  <div class="dark-mode">
    <pre>dark mode</pre>
    <button @click="toggleDark()">
      <SvgSprite
        v-if="isDark"
        symbol="ui-mode-light"
        size="32"
        role="img"
        aria-label="disable"
      />
      <SvgSprite
        v-else
        symbol="ui-mode-dark"
        size="32"
        role="img"
        aria-label="enable"
      />
    </button>
  </div>
</template>

<script setup lang="ts">
import { useToggle } from '@vueuse/core'

import { isDark } from '@/core/prefers'

const toggleDark = useToggle(isDark)
</script>

<style lang="scss" scoped>
.dark-mode,
[class*='dark-mode--'] {
  button {
    width: 4.4rem;
    height: 4.4rem;
    margin-left: 2rem;
    padding: 0.5rem;
    color: var(--c-primary);
    line-height: 1;
    background: none;
    border: 1px solid $c-main;
    border-radius: 50%;
  }
}
</style>
