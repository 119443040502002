<template>
  <div class="scroll">
    <pre>scroll</pre>
    <ul>
      <li>x: {{ scroll.x }}</li>
      <li>y: {{ scroll.y }}</li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { useScroll } from '@/utils/aware'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const scroll = useScroll({
  callback: ({ x, y }) => {
    console.log('Scroll', x, y)
  },
})
</script>
