<template>
  <div class="simulator">
    <h1
      class="simulator__title p-xxxl wrapper"
      v-html="content.intro_title"
    ></h1>
    <SimulatorContainer @details-show="onDetailsShow" />
    <Transition v-show="showDetails" name="g-fade" @enter="onDetailsEnter">
      <SimulatorDetails
        :content="{
          contactLink: content.contact_link,
          orderLink: content.order_link,
        }"
        @hide="onDetailsHide"
      />
    </Transition>
    <Tips v-if="content.tips" :content="content.tips" />
    <RibbonCta v-if="content.ctaRibbon" :content="content.ctaRibbon" />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useRoute } from 'vue-router'

import RibbonCta from '@/components/cta/Ribbon.vue'
import SimulatorContainer from '@/components/simulator/Container.vue'
import SimulatorDetails from '@/components/simulator/Details.vue'
import Tips from '@/components/ui/Tips.vue'
import { useSimulatorStore } from '@/stores/simulator'
import { prefersReducedMotion } from '@/utils/motion'

import type { PropType } from 'vue'
import type { RibbonCta as RibbonCtaType } from '@/components/cta/Ribbon.vue'
import type { Tips as TipsType } from '@/components/ui/Tips.vue'
import type { Link } from '@/types'

export interface SimulatorPage {
  tips: TipsType
  ctaRibbon?: RibbonCtaType
  intro_title: string
  order_link: Link
  contact_link: Link
  // TODO
  other_solution_cta: unknown
}

defineProps({
  content: {
    type: Object as PropType<SimulatorPage>,
    required: true,
  },
})

const showDetails = ref(false)
const route = useRoute()
const store = useSimulatorStore()

// Update simulator input with query value
// Query is filled when using the duo simulator
if (typeof route.query?.employeeNumber === 'string') {
  // Add value of query param to store
  store.employeeNumber = parseInt(route.query.employeeNumber, 10)
}

// Show details
const onDetailsShow = () => (showDetails.value = true)

// Scroll to details
const onDetailsEnter = (el: Element) =>
  el.scrollIntoView({ behavior: prefersReducedMotion() ? 'instant' : 'smooth' })

const onDetailsHide = () => (showDetails.value = false)
</script>

<style lang="scss" scoped>
.simulator__title {
  :deep(p) {
    @include section-title;

    &::after {
      margin-left: 0.2em;
    }

    &:not(:first-child) {
      &::before {
        content: none;
      }
    }

    &:not(:last-child) {
      &::after {
        content: none;
      }
    }
  }
}
</style>
