<template>
  <pre>v-outside</pre>
  <div v-if="outside.isOpen" v-outside="outside" class="outside">
    Outside outer<br /><br />

    <div
      v-if="outsideInner.isOpen"
      v-outside="outsideInner"
      class="outside-inner"
    >
      Outside inner
    </div>

    <button @click.prevent="outsideInner.isOpen = true">Open Inner</button>
  </div>
  <button @click.prevent="outside.isOpen = true">Open</button>
</template>

<script setup lang="ts">
import { reactive } from 'vue'

const close = () => {
  outside.isOpen = false
}

const closeInner = () => {
  outsideInner.isOpen = false
}
const outside = reactive({
  isOpen: false,
  action: close,
})

const outsideInner = reactive({
  isOpen: false,
  action: closeInner,
})
</script>

<style lang="scss" scoped>
.outside {
  width: 20rem;
  height: 20rem;
  margin: 0 auto;
  padding: 1rem;
  background: $c-gray-lighter;
}

.outside-inner {
  width: 10rem;
  height: 10rem;
  margin: 0 auto;
  background: $c-gray-light;
}
</style>
