<template>
  <div class="contact-coordinates wrapper m-xxxl bg-cobalt">
    <h2 class="contact-coordinates__title mb-l" v-html="content.title"></h2>
    <ul class="contact-coordinates__items">
      <li
        v-for="item in content.offices"
        :key="item.name"
        class="contact-coordinates__item"
      >
        <div class="contact-coordinates__item__name">{{ item.name }}</div>
        <address
          class="contact-coordinates__item__address"
          v-html="item.address"
        ></address>

        <a
          v-if="item.google_maps_url"
          :href="item.google_maps_url"
          class="contact-coordinates__item__map"
        >
          {{ t('contact.voir-sur-google-map') }}
        </a>

        <a
          v-if="item.phone"
          :href="item.phone.value"
          class="contact-coordinates__item__link"
        >
          <strong>T : </strong>{{ item.phone.label }}</a
        >
        <a
          v-if="item.fax"
          :href="item.fax.value"
          class="contact-coordinates__item__link"
        >
          <strong>F : </strong>{{ item.fax.label }}</a
        >
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import type { LabelValue } from '@/types'

interface Address {
  name: string
  address: string
  google_maps_url: string
  phone: LabelValue
  fax: LabelValue
}

export interface Coordinates {
  title: string
  offices: Address[]
}
</script>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import type { PropType } from 'vue'

defineProps({
  content: {
    type: Object as PropType<Coordinates>,
    required: true,
  },
})

const { t } = useI18n()
</script>

<style lang="scss" scoped>
$bp: m;

.contact-coordinates {
  @include mq($until: 'l') {
    margin-top: 5rem;
  }
}

.contact-coordinates__items {
  @extend %list-nostyle;

  @include mq($bp) {
    display: flex;
    justify-content: flex-start;
  }
}

.contact-coordinates__item {
  & + & {
    margin-top: $spacing * 2;
  }

  @include mq($bp) {
    width: col(6, var(--available-columns));

    & + & {
      margin-top: 0;
      margin-left: col(1, var(--available-columns));
    }
  }

  @include mq(xl) {
    width: col(5, var(--available-columns));
  }
}

.contact-coordinates__item__name {
  @extend %fw-bold;
}

.contact-coordinates__item__address {
  color: var(--c-text);
  font-style: normal;
  transition: color 0.2s;

  :deep(a) {
    @extend %link;

    margin-top: $spacing;
  }
}

.contact-coordinates__item__map {
  @extend %link;

  display: block;
  margin: $spacing * 0.5 0 $spacing;

  @include mq($bp) {
    margin: $spacing 0 $spacing * 2;
  }
}

.contact-coordinates__item__link {
  display: block;
  margin-top: $spacing;
  color: var(--c-text);
  transition: color 0.2s;

  &:hover,
  &:focus-visible {
    color: $c-cobalt;
  }

  @include mq($bp) {
    margin-top: $spacing * 2;
  }
}
</style>
