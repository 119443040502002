<template>
  <div
    ref="rootEl"
    class="solutions-sticky"
    :class="{ 'is-visible': showSticky }"
  >
    <div class="solutions-sticky__inner wrapper p-xs">
      <div class="solutions-sticky__title">
        {{ content.universe }}
        <img
          v-if="content.logo.url && !content.hide_logo"
          class="solutions-sticky__title__logo"
          :src="content.logo.url"
          :alt="content.slug"
        />
      </div>

      <Action
        v-if="content.link"
        class="solution-sticky__link"
        :content="{
          ...content.link,
          modifiers: ['btn'],
        }"
      />
    </div>
  </div>
</template>

<script lang="ts">
export interface Sticky {
  link: Link
  logo: {
    url: string
  }
  universe: string
  hide_logo?: boolean
  slug: string
}
</script>

<script setup lang="ts">
import { ref } from 'vue'

import { useUiStore } from '@/stores/ui'
import { useScroll } from '@/utils/aware'

import type { PropType } from 'vue'
import type { Link } from '@/types'

defineProps({
  content: {
    type: Object as PropType<Sticky>,
    required: true,
  },
})

const rootEl = ref<HTMLElement | null>(null)
const showSticky = ref(false)
const ui = useUiStore()

useScroll({
  callback: () => {
    if (window.scrollY > 200 && !showSticky.value) {
      showSticky.value = true
      ui.toggleHeader(false)
    } else if (window.scrollY <= 200 && showSticky.value) {
      showSticky.value = false
      ui.toggleHeader(true)
    }
  },
})
</script>

<style lang="scss" scoped>
$bp: m;

.solutions-sticky {
  position: fixed;
  z-index: $header-z-index - 1;
  top: 0;
  left: 0;
  width: 100%;
  background: $c-white;
  box-shadow: 0 0.4rem 2rem 0 rgba($c-black, 10%);
  opacity: 0;
  transform: translateY(-100%);
  transition: 0.3s;
  will-change: opacity, transform;

  &.is-visible {
    opacity: 1;
    transform: translateY(0);
  }
}

.solutions-sticky__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.solutions-sticky__title {
  @extend %ff-alt;
  @extend %fw-bold;

  margin-right: $spacing;

  @include mq($bp) {
    display: flex;
    align-items: center;
  }
}

.solutions-sticky__title__logo {
  display: block;
  width: 8rem;

  @include mq($bp) {
    width: 16rem;
    margin-left: $spacing * 1.5;
  }
}
</style>
