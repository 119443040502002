import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'

import { countriesAvailable } from '@/config/countries'
import { getLanguageDefault } from '@/config/languages'
import { useCountry } from '@/plugins/country'

export interface SimulatorInput {
  label: string
  min?: number
  max?: number
  value: number
  step: number
  showMinMax?: boolean
}

interface SimulatorValues {
  faceValue: SimulatorInput
  employeeNumber: SimulatorInput
  quotePart: number
  daysPerMonth: number
  rateISOC: number
  incomeTax: number
  socialTax: number
  employerTax: number
}

const simulatorValues: Record<
  (typeof countriesAvailable)[number],
  SimulatorValues
> = {
  be: {
    faceValue: {
      label: 'simulateur.valeur-faciale',
      value: 8,
      min: 2.1,
      max: 8,
      step: 0.1,
      showMinMax: true,
    },
    employeeNumber: {
      label: 'simulateur.nombre-d-employes',
      value: 1,
      min: 0,
      step: 1,
    },
    daysPerMonth: 20,
    quotePart: 1.09,
    rateISOC: 0.29,
    incomeTax: 0.45, // IPP
    socialTax: 0.1307, // rate ONSS perso
    employerTax: 0.25, // rate ONSS patronal
  },
  lu: {
    faceValue: {
      label: 'simulateur.valeur-faciale',
      value: 15,
      min: 5.6,
      max: 15,
      step: 0.1,
      showMinMax: true,
    },
    employeeNumber: {
      label: 'simulateur.nombre-d-employes',
      value: 1,
      min: 0,
      step: 1,
    },
    daysPerMonth: 18,
    quotePart: 2.8,
    rateISOC: 0.17,
    incomeTax: 0.237,
    socialTax: 0.12295,
    employerTax: 0.1298,
  },
}

export const useSimulatorStore = defineStore('simulator', () => {
  const country = useCountry()
  const languageDefault = getLanguageDefault(country)
  const values = simulatorValues[country]
  const employeeNumber = ref(values.employeeNumber.value)
  const faceValue = ref(values.faceValue.value)

  // Format price as 00,00 € or € 00,00 for nl/en
  const formatPrice = (value?: number) => {
    // Value could be undefined due to user input
    if (!value) {
      return 0
    }

    const route = useRoute()
    const { language } = route.params || languageDefault
    const price = value.toFixed(2).replace('.', ',')

    if (typeof language === 'string' && ['nl', 'en'].includes(language)) {
      return `€ ${price}`
    }

    return `${price} €`
  }
  // Format decimal number with percent sign
  const formatPercent = (value?: number) => {
    // Value could be undefined due to user input
    if (!value) {
      return 0
    }

    return `${(value * 100).toFixed(2).replace('.', ',')}%`
  }

  // 'Static' values
  const {
    daysPerMonth,
    quotePart: qpValue,
    rateISOC,
    incomeTax,
    socialTax,
    employerTax,
  } = values
  const quotePart = qpValue * daysPerMonth
  const dna =
    country === 'be' ? daysPerMonth * 2 * rateISOC : daysPerMonth * rateISOC

  // Computed values based on simulator
  const salary = computed(() => daysPerMonth * faceValue.value)
  const netPerWorker = computed(() => salary.value - quotePart)
  const grossPerWorker = computed(
    () => netPerWorker.value / (1 - incomeTax) / (1 - socialTax)
  )
  const onssPerWorker = computed(() => grossPerWorker.value * employerTax)
  const isocSavingPerWorker = computed(
    () => (grossPerWorker.value + onssPerWorker.value) * rateISOC
  )
  const netAugmentationPerWorker = computed(
    () => grossPerWorker.value + onssPerWorker.value - isocSavingPerWorker.value
  )
  const netAugmentation = computed(
    () => netAugmentationPerWorker.value * employeeNumber.value
  )
  const mealTicketCostPerWorker = computed(() => netPerWorker.value - dna)
  const mealTicketCost = computed(
    () => mealTicketCostPerWorker.value * employeeNumber.value
  )
  const netMonthlySavingsPercent = computed(
    // eslint-disable-next-line no-mixed-operators
    () => 1 - mealTicketCostPerWorker.value / netAugmentationPerWorker.value
  )
  const netMonthlySavings = computed(
    () =>
      (netAugmentationPerWorker.value - mealTicketCostPerWorker.value) *
      employeeNumber.value
  )

  return {
    daysPerMonth,
    rateISOC,
    values,
    quotePart,
    dna,
    employeeNumber,
    faceValue,
    formatPrice,
    formatPercent,
    salary,
    grossPerWorker,
    onssPerWorker,
    netPerWorker,
    netAugmentation,
    netAugmentationPerWorker,
    mealTicketCost,
    mealTicketCostPerWorker,
    netMonthlySavings,
    netMonthlySavingsPercent,
  }
})
