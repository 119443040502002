<template>
  <Transition
    :css="false"
    mode="out-in"
    @enter="onEnter"
    @leave="onLeave"
    @after-leave="onAfterLeave"
  >
    <div v-show="ui.showModal" class="modal" @click.self="onClose">
      <div class="modal-inner pb-section">
        <button class="modal__close" @click="onClose">
          <SvgSprite
            symbol="ui-close"
            size="24"
            role="img"
            class="icon"
            :aria-label="t('global-close')"
          />
        </button>

        <slot name="content" :on-close="onClose"></slot>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import gsap from 'gsap'
import { onUnmounted } from 'vue'
import { useI18n } from 'vue-i18n'

import { useUiStore } from '@/stores/ui'

// Emits
const emit = defineEmits<{
  show: []
  hide: []
  'after-leave': []
}>()

// Vars
const { t } = useI18n()
const ui = useUiStore()

// Methods
// Show popup and init events
const show = () => {
  ui.toggleModal(true)
  emit('show')
}

// Hide popup and cancel events
const hide = () => {
  window.removeEventListener('keyup', onKeyUp)
  ui.toggleModal(false)
  emit('hide')
}

// Event handling
// Transition popup in
const onEnter = (el: Element, done: () => void) => {
  const innerEl = el.querySelector('.popup-inner')
  const tl = gsap.timeline({
    onStart: () => {
      ui.toggleScroll(false)
    },
    onComplete: () => {
      window.addEventListener('keyup', onKeyUp)
      done()
    },
  })

  tl.fromTo(
    el,
    { opacity: 0 },
    {
      opacity: 1,
    }
  )

  innerEl &&
    tl.fromTo(
      innerEl,
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        y: 0,
      },
      '<=+0.1'
    )
}

const onLeave = (el: Element, done: () => void) => {
  const innerEl = el.querySelector('.popup-inner')
  const tl = gsap.timeline({
    onComplete: () => {
      ui.toggleScroll(true)

      done()
    },
  })

  innerEl &&
    tl.fromTo(
      innerEl,
      { opacity: 1, y: 0 },
      {
        opacity: 0,
        y: 50,
        duration: 0.2,
      }
    )

  tl.fromTo(
    el,
    { opacity: 1 },
    {
      opacity: 0,
      duration: 0.2,
    }
  )
}

const onAfterLeave = () => emit('after-leave')

// On close, remove lightbox
const onClose = () => hide()

// Handles key up event
// Check if escape has been pressed
const onKeyUp = (event: KeyboardEvent) => {
  const { key } = event

  switch (key) {
    case 'Escape':
      hide()
      break
    default:
      break
  }
}

onUnmounted(() => {
  hide()
  window.removeEventListener('keyup', onKeyUp)
})

defineExpose({ show, hide })
</script>

<style lang="scss" scoped>
.modal {
  @include get-all-space;

  position: fixed;
  z-index: $header-z-index + 2;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  padding-right: col(1);
  padding-left: col(1);
  background: rgba($c-white, 90%);

  @include mq(xl) {
    --popup-padding-top: #{$spacing * 4.8};

    padding-right: col(3);
    padding-left: col(3);
  }
}

.modal-inner {
  position: relative;
  width: 100%;
  max-width: var(--content-width-max);
  background: var(--c-bg);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: 0 0 3.2rem rgba(0, 0, 0, 20%);
}

.modal__close {
  @extend %button-nostyle;

  position: absolute;
  top: 0;
  right: 0;
  padding: $spacing;
  cursor: pointer;
  transition: color 0.2s;

  .icon {
    width: 2.4rem;
    height: 2.4rem;
    fill: currentcolor;
  }

  &:hover,
  &:focus {
    color: $c-cobalt;
  }

  @include mq(l) {
    top: -8rem;
    right: -6rem;

    .icon {
      width: 3.6rem;
      height: 3.6rem;
    }
  }
}
</style>
