<template>
  <pre>&lt;Lazy /&gt;</pre>
  <ul class="images">
    <li>
      <img src="@/assets/images/al-capone.jpg" alt="A Capone" />
    </li>
    <li>
      <img src="@/assets/images/charlin.jpg" alt="Charlin" />
    </li>
    <li>
      <img src="@/assets/images/l-oubina.jpg" alt="L Oubiña" />
    </li>
    <li>
      <img src="@/assets/images/pablo-escobar.jpg" alt="Pablo Escobar" />
    </li>
    <li>
      <img src="@/assets/images/sito-minanco.jpg" alt="Sito Miñanco" />
    </li>
  </ul>
</template>

<style lang="scss" scoped>
.images {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;

  img {
    margin: 0 auto;
  }
}
</style>
