<template>
  <div class="card-grid p-xl">
    <div class="wrapper">
      <h2 v-if="content.title" class="title" v-html="content.title"></h2>

      <div :class="`bg-${content.card.background_color}`" class="card">
        <div class="card-text">
          <img class="card-text-icon" :src="content.card.icon" alt="" />

          <h3 class="h4 bold">{{ content.card.title }}</h3>

          <div class="card-text-subtitle" v-html="content.card.subtitle"></div>

          <div class="card-text-content" v-html="content.card.text"></div>

          <Action
            v-if="content.card.link?.url"
            class="card-text-link"
            :content="{
              title: content.card.link.title,
              url: content.card.link.url,
              modifiers: ['btn'],
            }"
          />
        </div>

        <div
          class="card-items"
          :class="`card-items--${content.card.items.length}`"
        >
          <div
            v-for="(card, index) in content.card.items"
            :key="index"
            ref="cards"
            class="item"
            :class="{ actif: index === openedIndex }"
          >
            <div class="item-title-logo" @click="logoClick(index)">
              <img class="item-logo" :src="card.icon" alt="" />
              <span class="item-title h4 bold">{{ card.title }}</span>
            </div>

            <div class="item-content">
              <div class="item-text" v-html="card.text"></div>
              <Action
                v-if="card.link?.url"
                class="card-text-link"
                :content="{
                  title: card.link.title,
                  url: card.link.url,
                  modifiers: ['btn', 'small'],
                }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
// import type { PropType } from 'vue'
import { gsap } from 'gsap'
import { onMounted, ref } from 'vue'

import { useResize } from '@/utils/aware'

import type { PropType } from 'vue'
import type { Uri } from '@/types'

export interface CardGrid {
  title: string
  card: {
    icon: string
    title: string
    subtitle?: string // Autoriser le bold, wysiwyg ?
    text: string
    link?: Uri
    background_color: string // Deja présent pour SolutionsV2 // Solutions card V2
    items: {
      icon: string
      title: string
      text: string
      link?: Uri
    }[]
  }
}

const isMobile = ref(false)

onMounted(() => {
  useResize(onWindowResize)
})

const cards = ref()
const openedIndex = ref(-1)

const onWindowResize = () => {
  isMobile.value = window.innerWidth < 1024

  cards.value.forEach(resetSubmenuHeight)
  openedIndex.value = 0
  console.log('resize')
  openSubMenu(openedIndex.value)
}

defineProps({
  content: {
    type: Object as PropType<CardGrid>,
    required: true,
  },
})

const logoClick = (index: number) => {
  if (!isMobile.value) {
    return
  }

  if (index === openedIndex.value) {
    cards.value.forEach(resetSubmenuHeight)
    openedIndex.value = -1

    return
  }

  cards.value.forEach(resetSubmenuHeight)
  gsap.fromTo(
    cards.value[index].querySelector('.item-content'),
    { ease: 'power3.inOut', height: 0 },
    { ease: 'power3.inOut', height: 'auto' }
  )
  openedIndex.value = index
}

const openSubMenu = (index: number) => {
  gsap.fromTo(
    cards.value[index].querySelector('.item-content'),
    { ease: 'power3.inOut', height: 0 },
    { ease: 'power3.inOut', height: 'auto' }
  )

  openedIndex.value = index
}

const resetSubmenuHeight = (element: Element) => {
  gsap.to(element.querySelector('.item-content'), {
    ease: 'power3.inOut',
    height: 0,
  })
}
</script>

<style lang="scss" scoped>
$bp: 'l';

.wrapper {
  @include mq($until: xl) {
    padding: 0;
  }
}

.title {
  @include section-title;

  margin-bottom: 0;
  text-align: center;
}

.h4 {
  font-weight: 700;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  padding: 1rem;
  padding-top: 4rem;
  background-color: var(--c-accent-10);
  border: 2px solid var(--c-accent-60);
  border-radius: 4rem;
  box-shadow: 0 4px 40px 0 var(--c-accent-30);

  @include mq($bp) {
    padding: 2rem;
  }
}

.card-text-icon {
  width: 4.6rem;
  aspect-ratio: 1;
  margin-bottom: 1.4rem;
}

.card-text-subtitle {
  margin-top: 2rem;
}

.card-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 52rem;
  text-align: center;
}

.card-text-content {
  margin-top: 1.2rem;
  font-size: 1.5rem;
}

.card-text-link {
  display: block;
  margin-top: 2rem;
}

.card-items {
  display: grid;
  margin-top: 4rem;
  gap: 0.5rem;

  @for $i from 2 through 4 {
    &--#{$i} {
      @include mq($bp) {
        grid-template-columns: repeat($i, 1fr);
      }
    }
  }
}

.item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.7rem 3rem;
  background-color: $c-white;
  border: 2px solid var(--c-accent-60);
  border-radius: 3rem;

  @include mq($bp) {
    padding: 2rem;
  }

  @include mq(xl) {
    padding: 4rem;
  }
}

.item-title-logo {
  position: relative;
  display: flex;
  gap: 1rem;
  align-items: flex-start;

  @include mq($until: $bp) {
    width: 100%;

    &::before,
    &::after {
      position: absolute;
      content: '';
      top: 50%;
      display: block;
      background-color: var(--c-accent-60);
      transform: translateY(-50%);
      transition: all 0.1s ease-in-out;
    }

    &::before {
      right: 0;
      width: 1.5rem;
      height: 2px;
    }

    &::after {
      right: 0.65rem;
      width: 2px;
      height: 1.5rem;

      .actif & {
        opacity: 0;
        transform: rotate(90deg);
        transform-origin: top;
      }
    }
  }

  @include mq($bp) {
    gap: 1rem;
    flex-direction: column;
  }
}

.item-logo {
  width: auto;
  height: 2.4rem;
  pointer-events: none;

  @include mq($bp) {
    height: 6rem;
  }
}

.item-title {
  line-height: 2.5rem;

  @include mq($until: $bp) {
    font-size: 1.8rem;
  }
}

.item-text {
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  font-size: 1.5rem;
  line-height: 2.4rem;
}

.item-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  @include mq($until: $bp) {
    overflow: hidden;
    height: 0;
  }

  @include mq($bp) {
    /* stylelint-disable-next-line declaration-no-important */
    height: stretch !important;
  }
}
</style>
