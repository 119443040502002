<template>
  <div ref="rootEl" class="tabs">
    <ul class="tabs__nav" role="tablist">
      <li
        v-for="(item, index) in content.nav"
        :key="`tabs-nav-${item.value}`"
        role="presentation"
        class="tabs__nav__item"
        :class="{ 'is-selected': item.value === state.value }"
      >
        <slot
          :id="`tabs-nav-${item.value}`"
          :item="item"
          :index="index"
          :selected="state.value"
          :on-selected="onSelected"
          :on-focus="onFocus"
          name="navItem"
        >
          <Action
            :id="`tabs-nav-${item.value}`"
            :content="{
              label: item.label,
              tag: 'button',
              modifiers: ['bg-primary'],
            }"
            role="tab"
            :aria-controls="`tabs-panel-${item.value}`"
            :aria-selected="item.value === state.value"
            :aria-setsize="content.nav.length"
            :aria-posinset="index"
            :tabindex="item.value === selected ? 0 : -1"
            @click="onSelected(item.value)"
            @focus="onFocus()"
          />
        </slot>
      </li>
    </ul>

    <div class="tabs__panels">
      <TransitionGroup>
        <div
          v-for="(panel, key) in content.panels"
          v-show="typeof key === 'string' && key === state.value"
          :id="`tabs-panel-${key}`"
          :key="`tabs-panel-${key}`"
          :aria-labelledby="`tabs-nav-${key}`"
          :aria-hidden="key !== state.value"
          role="tabpanel"
          tabindex="-1"
          class="tabs__panels__item"
        >
          <slot :key="key" name="panelItem" :item="panel"> </slot>
        </div>
      </TransitionGroup>
    </div>
  </div>
</template>

<script lang="ts">
import type { LabelValue } from '@/types'

export interface Tabs<T> {
  nav: LabelValue[]
  panels: Record<string, T>
}
</script>

<script setup lang="ts">
import { useCycleList } from '@vueuse/core'
import { ref } from 'vue'

import type { PropType } from 'vue'

const props = defineProps({
  content: {
    type: Object as PropType<Tabs<unknown>>,
    required: true,
  },
  selected: {
    type: String,
    required: false,
    default: undefined,
  },
})

// Refs
const rootElRef = ref<HTMLElement | null>(null)
const { state, next, prev } = useCycleList(props.content.nav)

// Emit
const emit = defineEmits<{
  change: [value: string]
}>()

// Update state value by finding item with value
const select = (value: string) => {
  const itemToSelect = props.content.nav.find(item => item.value === value)

  if (itemToSelect) {
    state.value = itemToSelect
    emit('change', state.value.value)
  }
}

// Event handling
// Handle click on left arrow: select previous tab
const onPrev = () => {
  prev()
  emit('change', state.value.value)
}

// Handle click on right arrow: select next tab
const onNext = () => {
  next()
  emit('change', state.value.value)
}

// Handle direct click on button
const onSelected = (value: string) => {
  // if the user clicked on the selected button, return
  if (state.value.value === value) {
    return
  }

  select(value)
}

// On button focus, listen to keyup to focus other buttons
const onFocus = () => window.addEventListener('keyup', onKeyup)

// Focus to other tabs on arrows keyup
// https://a11y-style-guide.com/style-guide/section-structure.html#kssref-structure-tabs
const onKeyup = (e: KeyboardEvent) => {
  // If the target element is not a tabs button anymore, remove event listener
  if (!e.target || !(e.target as Element).id.includes('tabs-nav')) {
    window.removeEventListener('keyup', onKeyup)

    return
  }

  switch (e.key) {
    case 'ArrowLeft':
      onPrev()

      break
    case 'ArrowRight':
      onNext()

      break
    default:
      break
  }

  // Focus new element
  const elToFocus = rootElRef.value?.querySelector(
    `#tabs-panel-${state.value.value}`
  ) as HTMLButtonElement | undefined

  elToFocus?.focus()
}
</script>

<styles lang="scss" scoped>
.tabs__nav {
  @extend %list-nostyle;
}
</styles>
