<template>
  <div class="appear">
    <p>
      <span role="img" aria-labelledby="appear-down"> 👇 </span>
      <span id="appear-down">appear</span>
      <span role="img" aria-labelledby="appear-down"> 👇 </span>
    </p>
    <pre ref="root">

  Appeared: {{ count }} {{ count > 1 ? 'times' : 'time' }}

  * isInViewport: {{ isIn }}
  * isFullyInViewport: {{ isFully }}
  * position: {{ pos }}
    </pre>
    <p>
      <span role="img" aria-labelledby="appear-up"> ☝️ </span>
      <span id="appear-up">appear</span>
      <span role="img" aria-labelledby="appear-up"> ☝️ </span>
    </p>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { useAppear } from '@/utils/aware'

const count = ref(0)

// FEATURE: appear
const root = ref(null)
const isIn = ref(false)
const isFully = ref(false)
const pos = ref()

useAppear(
  root,
  ({ isInViewport, isFullyInViewport, position }) => {
    if (isInViewport) {
      count.value += 1
    }

    isIn.value = isInViewport
    isFully.value = isFullyInViewport
    pos.value = position
  },
  { once: true }
)
</script>

<style lang="scss" scoped>
.appear,
[class*='appear--'] {
  pre {
    margin: 50vh 0;
  }
}
</style>
