<template>
  <div class="privacy-policy wysiwyg">
    <h1 class="privacy-policy__title">
      <strong>{{ content.title }}</strong>
    </h1>

    <ul class="privacy-policy__nav">
      <li v-for="(nav, index) in content.sections" :key="index">
        <div v-if="isObject(nav)">
          <a :href="`#${nav.slug}`" @click="scrollTo($event, nav.slug)">
            {{ nav.title }}
          </a>
          <ul v-if="nav.subsections">
            <li v-for="(item, data) of nav.subsections" :key="data">
              <a href="#" @click="scrollTo($event, item.slug)">{{
                item.title
              }}</a>
            </li>
          </ul>
        </div>
      </li>
    </ul>

    <div
      v-for="(section, index) in content.sections"
      :key="index"
      class="privacy-policy__section"
    >
      <h2 :id="section.slug" class="privacy-policy__section__title">
        {{ section.title }}
      </h2>

      <div v-if="section.subsections">
        <div
          v-for="(item, j) in section.subsections"
          :key="j"
          class="privacy-policy__subsection"
        >
          <h3 :id="item.slug" class="privacy-policy__subsection__title">
            {{ item.title }}
          </h3>

          <div class="privacy-policy__text" v-html="item.text"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps({
  content: {
    type: Object,
    required: true,
  },
})

const isObject = (obj: Object) => obj && typeof obj === 'object'

const scrollTo = (e: Event, slug: string) => {
  e.preventDefault()

  const id = document.getElementById(slug)

  if (id) {
    const top = id.offsetTop - 120
    window.scrollTo({
      top,
      behavior: 'smooth',
    })
  }
}
</script>

<style lang="scss" scoped>
.privacy-policy,
[class*='privacy-policy--'] {
  @include l-space; // sass-lint:disable-line mixins-before-declarations

  max-width: 76rem;
  margin: 0 auto;
  padding: 0 2rem;
  padding-bottom: 6rem;

  @include mq(m) {
    padding: 0 4rem;
    padding-bottom: 12rem;
  }
}

.privacy-policy__title {
  margin-bottom: $spacing * 2.5;
}

.privacy-policy__nav {
  margin-bottom: $spacing * 2;
  cursor: pointer;

  a {
    color: $c-blue;
  }

  ul {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  @include mq(m) {
    margin-bottom: $spacing * 4;
  }
}

.privacy-policy__section {
  margin-bottom: $spacing * 4;

  &:last-child {
    margin-bottom: 0;
  }

  @include mq(m) {
    margin-bottom: $spacing * 6;
  }
}

.privacy-policy__section__title {
  margin-bottom: $spacing * 2;
}

.privacy-policy__subsection {
  margin-bottom: $spacing * 3;

  &:last-child {
    margin-bottom: 0;
  }
}

.privacy-policy__subsection__title {
  margin-bottom: $spacing;
}
</style>
