<template>
  <div id="details" class="simulator-details p-xxxl bg-cobalt">
    <div class="simulator-details-wrapper wrapper">
      <button class="simulator-details__close" @click="onHide">
        <SvgSprite class="icon" symbol="ui-plus" size="36" role="img" />
        <span>{{ t('global.fermer') }}</span>
      </button>

      <h2 class="simulator-details__title h3">
        {{ t('simulateur.details-du-calcul') }}
      </h2>
      <div class="simulator-details__subtitle lead">
        {{ t('simulateur.comparaison-du-cout-mensuel-brut-pour-l-employeur') }}
        <small>
          *
          {{
            t(
              'simulateur.sur-base-de-20-cheques-repas-par-mois-et-une-intervention-de-l-employe-de-1-09€'
            )
          }}
        </small>
      </div>

      <div class="simulator-details___content">
        <SimulatorAmounts
          :content="{
            title: t('simulateur-details.en-salaire'),
            items: [
              {
                label: `${t('simulateur-details.pouvoir-d-achat')}
                  <small>${t('simulateur-details.pour-le-beneficiaire')}</small>`,
                value: store.formatPrice(store.netPerWorker),
              },
              {
                label: `${t('simulateur-details.valeur-faciale')}
                  <small>${t(
                    'simulateur-details.sur-base-d-un-taux-marginal-IPP-45%-et-du-taux-ONSS-personnel-de-13,07%'
                  )}</small>`,
                value: store.formatPrice(store.grossPerWorker),
              },
              {
                label: `${t('simulateur-details.taux-ONSS-patronale')}
                  <small>${t('simulateur-details.pour-le-beneficiaire')}</small>`,
                value: store.formatPrice(store.onssPerWorker),
              },
              {
                label: `${t('simulateur-details.cout-patronal-mensuel')}
                  <small>${t('simulateur-details.par-beneficiaire')}</small>`,
                value: store.formatPrice(store.netAugmentationPerWorker),
              },
            ],
            footer: {
              label: t('simulateur-details.cout-mensuel'),
              value: store.formatPrice(store.netAugmentation),
            },
          }"
          class="simulator-details__content__item"
        />

        <SimulatorAmounts
          :content="{
            title: `${t('simulateur-details.avec-tickets-restaurant®')}®*`,
            items: [
              {
                label: `${t('simulateur-details.pouvoir-d-achat')}
                  <small>${t('simulateur-details.pour-le-beneficiaire')}</small>`,
                value: store.formatPrice(store.netPerWorker),
              },

              {
                label: `${t('simulateur-details.depense-non-admise')}
                  <small>${t(
                    'simulateur-details.sur-base-d-un-taux-ISOC-de-25%-2€-deductibles-par-titre'
                  )}</small>`,
                value: store.formatPrice(store.dna),
              },
              {
                label: `${t('simulateur-details.cout-patronal-mensuel')}
                  <small>${t('simulateur-details.par-beneficiaire')}</small>`,
                value: store.formatPrice(store.mealTicketCostPerWorker),
              },
            ],
            footer: {
              label: t('simulateur-details.cout-mensuel'),
              value: store.formatPrice(store.mealTicketCost),
            },
          }"
          class="simulator-details__content__item"
        />
      </div>

      <div class="simulator-details__footer">
        <h3 class="simulator-details__footer__title">
          {{ t('simulateur.economie-mensuel') }}
        </h3>
        <p class="simulator-details__footer__savings h2">
          {{ store.formatPrice(store.netMonthlySavings) }}
        </p>
        <p class="simulator-details__footer__percent">
          <span>{{ t('simulateur.soit') }}</span>
          {{ store.formatPercent(store.netMonthlySavingsPercent) }}
          <span>{{ t('simulateur.moins-cher') }}</span>
          <small>{{
            t('simulateur.par-rapport-à-une-augmentation-de-salaire')
          }}</small>
        </p>

        <div
          v-if="content.orderLink || content.contactLink"
          class="simulator-details__footer__links"
        >
          <Action
            v-if="content.orderLink"
            :content="{ ...content.orderLink, modifiers: ['btn'] }"
            @click="onOrderClick"
          />
          <Action
            v-if="content.contactLink"
            :content="{ ...content.contactLink, modifiers: ['btn', 'black'] }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import SimulatorAmounts from '@/components/simulator/Amounts.vue'
import { useSimulatorStore } from '@/stores/simulator'
import { push } from '@/utils/tracking'

import type { PropType } from 'vue'
import type { Link } from '@/types'

interface SimulatorDetails {
  orderLink: Link
  contactLink: Link
}

defineProps({
  content: {
    type: Object as PropType<SimulatorDetails>,
    required: true,
  },
})

const store = useSimulatorStore()
const { t } = useI18n()
const emit = defineEmits<{
  hide: []
}>()

// Handle click on hide button
const onHide = () => emit('hide')

// Track click on order CTA
const onOrderClick = () =>
  push({
    event: 'simulator',
    eventCategory: 'simulator',
    eventAction: 'click',
    eventLabel: 'order',
  })
</script>

<style lang="scss" scoped>
$bp: l;

.simulator-details {
  position: relative;
  background: $c-secondary;
}

.simulator-details__close {
  @extend %button-nostyle;

  position: absolute;
  top: 0;
  right: 0;
  padding: $spacing;
  color: var(--c-accent);
  cursor: pointer;
  transition: color 0.1s;

  .icon {
    fill: currentcolor;
    transform: rotate(45deg);
  }

  span {
    @extend %visually-hidden;
  }

  &:hover,
  &:focus-visible {
    color: var(--c-accent-40);
  }

  @include mq($bp) {
    top: $spacing * 3;
    right: col(1);
  }
}

.simulator-details__title {
  @extend %fw-bold;
  @extend %text-center;
}

.simulator-details__subtitle {
  @extend %text-center;

  margin: $spacing * 0.75 col(1) $spacing * 1.5;

  small {
    @extend %fw-normal;

    display: block;
    margin-top: $spacing * 0.25;
    color: var(--c-accent);
  }

  @include mq($bp) {
    margin: $spacing col(3) $spacing * 2;
  }
}

// Content
.simulator-details___content {
  position: relative;
  margin-bottom: $spacing * 1.5;
  background: $c-white;
  border: 0.1rem solid var(--c-accent-border);
  border-radius: 1.5rem;

  &::after {
    @include triangle(bottom, 2.25rem);
    @include center-x;

    content: '';
    bottom: -2.25rem;
    color: $c-black;
  }

  @include mq($bp) {
    display: flex;
    margin: 0 col(1, var(--available-columns)) $spacing * 1.5;
  }
}

.simulator-details__content__item {
  overflow: hidden;

  &:last-child {
    border-radius: 1.5rem;
  }

  :deep(.simulator-amounts__list) {
    small {
      @extend %tiny-fs;

      display: block;
      margin-top: -0.2rem;
      color: $c-grey;
      line-height: 1.2;
    }
  }

  @include mq($bp) {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 0;
    border-radius: 0 0 0 1.5rem;

    &:last-child {
      border-radius: 0 0 1.5rem;
    }

    :deep(.simulator-amounts__title) {
      padding-top: var(--padding);
    }
  }
}

// Footer
.simulator-details__footer {
  @extend %text-center;

  position: relative;
  margin: 0 auto;
  padding: $spacing * 2 col(1, var(--available-columns));
  background: $c-white;
  border: 0.1rem solid var(--c-accent-border);
  border-radius: 2rem;

  @include mq($bp) {
    margin: 0 col(4, var(--available-columns));
  }
}

.simulator-details__footer__title {
  @extend %fw-normal;

  color: var(--c-accent);
  font-size: 1em;
  line-height: 1.7;
}

.simulator-details__footer__savings {
  @extend %fw-bold;

  margin-top: 0.5rem;
}

.simulator-details__footer__percent {
  margin: $spacing 0;

  /* stylelint-disable-next-line no-descending-specificity */
  small {
    display: block;
    margin-top: 0.5rem;
    color: var(--c-accent);
  }
}

.simulator-details__footer__links {
  display: flex;
  flex-direction: column;
  align-items: center;

  > * + * {
    margin-top: $spacing * 0.5;
  }

  @include mq($bp) {
    display: block;

    > * + * {
      margin: 0 0 0 $spacing * 0.5;
    }
  }
}
</style>
