<template>
  <div class="contact">
    <section v-if="content.hero" class="contact__heading wrapper p-xxxl">
      <h1
        class="contact__heading__title"
        :class="`title-${content.titleColor}`"
        v-html="content.hero.title"
      ></h1>
      <div
        v-if="content.hero.htmltext"
        class="contact__heading__baseline mt-l"
        v-html="content.hero.htmltext"
      ></div>
      <div
        v-if="content.hero.intro"
        class="contact__heading__introduction mt-l"
        v-html="content.hero.intro"
      ></div>
    </section>

    <ContactProfiles :content="content.profiles" @tab-change="onTabChange" />

    <ContactCoordinates :content="content.contact_coordinates" />
    <ContactSupport
      :content="{
        title: content.support_title,
        items: content.support_items,
      }"
    />

    <UiTips
      v-show="activeTab === 'companies'"
      :content="content.company_tips"
    />
    <UiTips
      v-show="activeTab === 'merchants'"
      :content="content.merchant_tips"
    />
    <UiTips
      v-show="activeTab === 'employee'"
      :content="content.employee_tips"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import ContactCoordinates from '@/components/contact/Coordinates.vue'
import ContactProfiles from '@/components/contact/Profiles.vue'
import ContactSupport from '@/components/contact/Support.vue'
import UiTips from '@/components/ui/Tips.vue'

import type { PropType } from 'vue'
import type { Coordinates } from '@/components/contact/Coordinates.vue'
import type { Profiles } from '@/components/contact/Profiles.vue'
import type { Tips } from '@/components/ui/Tips.vue'
import type { LabelValue } from '@/types'

interface Contact {
  company_tips: Tips
  contact_coordinates: Coordinates
  employee_tips: Tips
  hero: {
    title: string
    htmltext?: string
    intro?: string
  }
  merchant_tips: Tips
  profiles: Profiles
  support_title: string
  support_items: LabelValue[]
  titleColor?: string
}

const props = defineProps({
  content: {
    type: Object as PropType<Contact>,
    required: true,
  },
})

const activeTab = ref(Object.keys(props.content.profiles.items)[0])

// On tab change, update ref to show corresponding tips
const onTabChange = (value: string) => (activeTab.value = value)
</script>

<style scoped lang="scss">
$bp: m;

.contact__heading__title {
  @extend %fw-bold;
  @include section-title;
}

.contact__heading__introduction {
  @include mq(l) {
    width: col(12, var(--available-columns));
  }
}
</style>
