<template>
  <div class="solution">
    <FocusBasic
      :content="{
        background_color: content.background_color,
        headline: content.focus.headline,
        text: content.baseline,
        hide_logo: content.hide_logo,
        links: [content.header_link1, content.header_link2],
        title: content.title,
        picture: content.focus.picture,
      }"
      @modal-trigger="onModalTrigger"
    />

    <SolutionModal v-if="content.three_way" :content="content.three_way" />

    <SolutionSticky
      :content="{
        link: content.menu_sticky_link,
        logo: content.logo,
        universe: content.focus.headline,
        hide_logo: content.hide_logo,
        slug: content.slug,
      }"
    />

    <DuoTextCpt
      v-if="content.intro_text_duo"
      :content="content.intro_text_duo"
    />

    <Video v-if="content.video.url" :content="content.video" />

    <KeyfactsProduct
      v-if="content.keyfacts_product"
      :content="content.keyfacts_product"
    />

    <DuoCombo v-if="content.combo" :content="content.combo" />

    <Testimony
      v-if="content.slider_testimony"
      :content="content.slider_testimony"
    />

    <TipsCpt v-if="content.tips.items" :content="content.tips" />

    <GCtaPictureRight
      v-if="content.last_chance"
      :content="content.last_chance"
    />

    <Solutions
      v-if="content.solutions_grid"
      :content="content.solutions_grid"
    />

    <CtaRibbon v-if="content.ctaRibbon" :content="content.ctaRibbon" />

    <GridNews v-if="content.news_grid.cards" :content="content.news_grid" />
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'

import GCtaPictureRight from '@/components/cta/PictureRight.vue'
import CtaRibbon from '@/components/cta/Ribbon.vue'
import DuoCombo from '@/components/duo/Combo.vue'
import DuoTextCpt from '@/components/duo/Text.vue'
import Testimony from '@/components/engagement/Testimony.vue'
import FocusBasic from '@/components/focus/Basic.vue'
import GridNews from '@/components/grid/News.vue'
import Solutions from '@/components/grid/SolutionsV2.vue'
import KeyfactsProduct from '@/components/keyfacts/Product.vue'
import SolutionModal from '@/components/solution/Modal.vue'
import SolutionSticky from '@/components/solution/Sticky.vue'
import TipsCpt from '@/components/ui/Tips.vue'
import Video from '@/components/ui/Video.vue'
import { useUiStore } from '@/stores/ui'

import type { PropType } from 'vue'
import type { CtaPictureRight } from '@/components/cta/PictureRight.vue'
import type { RibbonCta } from '@/components/cta/Ribbon.vue'
import type { DuoCombo as DuoComboType } from '@/components/duo/Combo.vue'
import type { DuoText } from '@/components/duo/Text.vue'
import type { Testimony as TestimonyT } from '@/components/engagement/Testimony.vue'
import type { FocusBasic as FocusBasicType } from '@/components/focus/Basic.vue'
import type { NewsGrid } from '@/components/grid/News.vue'
import type { SolutionGrid as SolutionGridType } from '@/components/grid/SolutionsV2.vue'
import type { KeyfactsProduct as KeyfactsProductType } from '@/components/keyfacts/Product.vue'
import type { SolutionModal as SolutionModalType } from '@/components/solution/Modal.vue'
import type { Sticky } from '@/components/solution/Sticky.vue'
import type { Tips } from '@/components/ui/Tips.vue'
import type { Video as VideoT } from '@/components/ui/Video.vue'
import type { Link, Picture } from '@/types'

interface Solution {
  background_color: string
  baseline: string
  combo: DuoComboType
  ctaRibbon: RibbonCta
  focus: FocusBasicType
  header_link1: Link
  header_link2?: Link
  hide_logo: boolean
  three_way: SolutionModalType
  illustration_picture: Picture
  intro_text_duo: DuoText
  keyfacts_product: KeyfactsProductType
  logo: Sticky['logo']
  menu_sticky_link: Link
  news_grid: NewsGrid
  slug: string
  solutions_grid: SolutionGridType
  tips: Tips
  title: string
  video: VideoT
  last_chance: CtaPictureRight
  slider_testimony?: TestimonyT
}

defineProps({
  content: {
    type: Object as PropType<Solution>,
    required: true,
  },
})

const ui = useUiStore()
const route = useRoute()

const onModalTrigger = () => ui.toggleModal(true)

onMounted(() => {
  // Show order modal
  if (route.hash === '#order') {
    ui.toggleModal(true)
  }
})
</script>
