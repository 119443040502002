<template>
  <section class="contact-profiles form-parent p-xxxl">
    <h2 class="contact-profiles__title wrapper">{{ content.title }}</h2>

    <UiTabs
      class="contact-profiles__nav bg-cobalt mt-l wrapper"
      :content="tabs"
      @change="onTabChange"
    >
      <template #navItem="{ id, item, selected, index, onFocus, onSelected }">
        <button
          :id="id"
          class="contact-profiles__nav__item form-switcher h4"
          role="tab"
          :aria-controls="`tabs-panel-${item.value}`"
          :aria-selected="item.value === selected"
          :aria-setsize="tabs.nav.length"
          :aria-posinset="index"
          :tabindex="item.value === selected ? 0 : -1"
          @click="onSelected(item.value)"
          @focus="onFocus()"
        >
          {{ item.label }}
        </button>
      </template>

      <template #panelItem="{ item }">
        <div
          v-if="isProfile(item) && item.cta"
          class="contact-profiles__panel mt-xxxl"
        >
          <div class="contact-profiles__panel__cta h4">
            <div v-html="item.cta.title"></div>
            <Action
              :content="{
                ...item.cta.link,
                modifiers: ['btn'],
                icon: 'external',
              }"
              class="mt-l"
            />
          </div>
          <GlobalForm
            v-if="item.form"
            class="contact-profiles__panel__form"
            :content="item.form"
          />
        </div>
      </template>
    </UiTabs>
  </section>
</template>

<script lang="ts">
import type { FormContent } from '@monofront/bob'
import type { Link } from '@/types'

interface Profile {
  label: string
  form?: FormContent
  cta?: {
    title: string
    link: Link
  }
}

export interface Profiles {
  title: string
  items: Record<string, Profile>
}
</script>

<script setup lang="ts">
import GlobalForm from '@/components/global/Form.vue'
import UiTabs from '@/components/ui/Tabs.vue'

import type { PropType } from 'vue'
import type { Tabs } from '@/components/ui/Tabs.vue'

const props = defineProps({
  content: {
    type: Object as PropType<Profiles>,
    required: true,
  },
})

const emit = defineEmits<{
  'tab-change': [value: string]
}>()

const tabs: Tabs<Profile> = {
  nav: Object.keys(props.content.items).map(key => ({
    label: props.content.items[key].label,
    value: key,
  })),
  panels: props.content.items,
}

const isProfile = (item: unknown): item is Profile =>
  Boolean((item as Profile).label)

const onTabChange = (value: string) => emit('tab-change', value)
</script>

<style lang="scss" scoped>
$bp: l;

.contact-profiles {
  background: $c-secondary;
  scroll-margin-top: $header-height;

  @include mq($until: $bp) {
    padding-bottom: 4rem;
  }
}

.contact-profiles__title {
  @extend %fw-bold;
}

.contact-profiles__nav {
  :deep(.tabs__nav) {
    display: flex;
    flex-direction: column;
    gap: $spacing * 0.5;

    @include mq($bp) {
      flex-direction: row;
      gap: $spacing;
    }
  }

  :deep(.tabs__nav__item) {
    @include mq($bp) {
      flex: 1;
    }
  }
}

.contact-profiles__panel {
  @include mq($bp) {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
  }
}

.contact-profiles__panel__form {
  @include mq($bp) {
    align-self: stretch;
    width: col(12, var(--available-columns));
  }

  @include mq(wrapper) {
    width: col(10.5, var(--available-columns));
  }
}

.contact-profiles__panel__cta {
  margin-bottom: $spacing;
  padding: $spacing;
  background: var(--c-accent-20);
  border-radius: 3rem;

  @include mq($bp) {
    flex: 1;
    margin: 0;
    padding: $spacing * 1.5;

    &:not(:last-child) {
      margin-left: col(0.5, var(--available-columns));
    }
  }

  @include mq(wrapper) {
    padding: $spacing * 2;

    &:not(:last-child) {
      margin-left: col(0.5, var(--available-columns));
    }
  }
}
</style>
