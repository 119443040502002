<template>
  <div
    v-if="content.ctas?.length"
    class="wrapper cta-grid"
    :class="`bg-${content.background_color}`"
  >
    <div class="cta-grid__inner">
      <h2
        class="cta-grid__title"
        :class="`title-${content.titleColor}`"
        v-html="content.title"
      ></h2>
      <ul v-if="editable" class="cta-grid__tools--editable">
        <li
          v-for="(cta, index) in content.ctas"
          :key="index"
          class="cta-grid__tools__el"
          :class="[
            cta.background_color ? `bg-${cta.background_color}` : 'bg-white',
            `cta-grid__tools__el--${cta.type}`,
          ]"
        >
          <p class="cta-grid__tools__el__headline">{{ cta.headline }}</p>
          <h3 class="cta-grid__tools__el__title" v-html="cta.title"></h3>

          <Action
            v-if="cta.link"
            :content="{
              title: cta.link.title,
              url: cta.link.url,
              modifiers: ['btn'],
            }"
          />

          <div
            v-if="cta.picture && cta.type != 'social'"
            class="cta-grid__tools__ribbon"
          >
            <img
              :src="cta.picture.src"
              :srcset="cta.picture.srcset"
              :alt="cta.picture.alt"
              :width="cta.picture.width"
              :height="cta.picture.height"
            />
          </div>

          <ul
            v-if="cta.type === 'apps' && cta.links"
            class="cta-grid__tools__el__stores store-list"
          >
            <li v-for="(link, i) in cta.links" :key="i" class="store-list__el">
              <a class="store-list__el__wrapper" target="_blank" :href="link">
                <img
                  v-if="i.toString() === 'ios'"
                  src="@/assets/images/app/ios.svg"
                  alt="ios Icon"
                />
                <img
                  v-else
                  src="@/assets/images/app/android.svg"
                  alt="Android Icon"
                />
              </a>
            </li>
          </ul>

          <ul
            v-if="cta.type === 'social' && cta.links"
            class="cta-grid__tools__el__socials social-list"
          >
            <li v-for="(link, j) of cta.links" :key="j" class="social-list__el">
              <a :href="link" target="_blank" class="social-list__el__link">
                <SvgSprite
                  :symbol="`social-${j}`"
                  size="40"
                  role="img"
                  aria-label="disable"
                />
              </a>
            </li>
          </ul>
        </li>
      </ul>
      <!-- <ul class="cta-grid__tools" v-if="!editable">
      <li class="cta-grid__tools__el {{grid.ctas[0].background_color}}">
        <p class="cta-grid__tools__el__headline">MyEdenred</p>
        <h3 class="cta-grid__tools__el__title" translate>
          utilisateur.votre-espace <strong translate>utilisateur.personnel</strong>
        </h3>
        <a :href="grid.ctas[0].link.url"
           class="btn cta"
           :target="grid.ctas[0].link.target"
        >
          {{ grid.ctas[0].link.title }}
        </a>
        <div class="cta-grid__tools__ribbon">
          <img
            :src="grid.ctas[0].picture.src"
            :srcset="grid.ctas[0].picture.srcset"
            :alt="grid.ctas[0].picture.alt"
          >
        </div>
      </li>
      <li class="cta-grid__tools__el {{grid.ctas[1].background_color}}">
        <p class="cta-grid__tools__el__headline">MyEdenred</p>


        <ul class="cta-grid__tools__el__stores store-list">
          <li class="store-list__el">
            <a class="store-list__el__wrapper" target="_blank" :href="grid.ctas[1].links.ios">
              <img class="store-list__el__img"
                   :src="getLogoUrl(appstore)"
                   :alt=""
              >
            </a>
          </li>
          <li class="store-list__el">
            <a class="store-list__el__wrapper" target="_blank" :href="grid.ctas[1].links.android">
              <img class="store-list__el__img"
                   :src="getLogoUrl(googleplay)"
                   :alt=""
              >
            </a>
          </li>
        </ul>
        <div class="cta-grid__tools__ribbon">
          <img
            :src="grid.ctas[1].picture.src"
            :srcset="grid.ctas[1].picture.srcset"
            :alt="grid.ctas[1].picture.alt"
          >
        </div>
      </li>
      <li class="cta-grid__tools__el--social">
        <p class="cta-grid__tools__el__headline" translate>
          utilisateur.social
        </p>
        <h3 class="cta-grid__tools__el__title" translate>
          utilisateur.les-reseaux-sociaux
        </h3>
        <ul v-if="social_networks" class="cta-grid__tools__el__socials social-list">
          <li v-if="social_networks.facebook"
              class="social-list__el {{grid.background_color}}"
          >
            <a href="{{social_networks.facebook}}"
               target="_blank"
               class="social-list__el__link"
            >
              <ed-icon viewbox="0 0 9 18" hide="true" icon="social-facebook"></ed-icon>
            </a>
          </li>
          <li v-if="social_networks.twitter"
              class="social-list__el {{grid.background_color}}"
          >
            <a href="{{social_networks.twitter}}"
               target="_blank"
               class="social-list__el__link"
            >
              <ed-icon viewbox="0 0 16 13" hide="true" icon="social-twitter"></ed-icon>
            </a>
          </li>
          <li v-if="social_networks.linkedin"
              class="social-list__el {{grid.background_color}}"
          >
            <a href="{{social_networks.linkedin}}"
               target="_blank"
               class="social-list__el__link"
            >
              <ed-icon viewbox="0 0 18 18" hide="true" icon="social-linkedin"></ed-icon>
            </a>
          </li>
          <li v-if="social_networks.instagram"
              class="social-list__el {{grid.background_color}}"
          >
            <a href="{{social_networks.instagram}}"
               target="_blank"
               class="social-list__el__link"
            >
              <ed-icon viewbox="0 0 40 40" hide="true" icon="social-instagram"></ed-icon>
            </a>
          </li>
          <li v-if="social_networks.youtube"
              class="social-list__el {{grid.background_color}}"
          >
            <a href="{{social_networks.youtube}}"
               target="_blank"
               class="social-list__el__link"
            >
              <ed-icon viewbox="0 0 40 40" hide="true" icon="social-youtube"></ed-icon>
            </a>
          </li>
        </ul>
      </li>
    </ul> -->
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
  editable: {
    type: Object,
    required: true,
  },
})

const content = computed(() =>
  props.editable ? props.editable : props.content
)
</script>

<style lang="scss" scoped>
$bp: 'l';

.cta-grid {
  position: relative;
  padding-top: 5rem;
  padding-bottom: 5rem;
  background: var(--c-accent-20);

  @include mq(xl) {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
}

.cta-grid__tools__el {
  background-color: var(--c-accent-light);
}

.cta-grid__title {
  @include section-title;

  position: relative;
  z-index: 1;
  display: block;
  margin: 0 auto;
  text-align: center;

  @include mq($bp) {
    width: calc(100% / 20 * 14);
  }
}

.cta-grid__tools,
[class*='cta-grid__tools--'] {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 4rem 0 0;
  padding: 0;
  list-style: none;

  @include mq(xl) {
    margin: 7rem 0 0;
  }
}

.cta-grid__tools__el,
[class*='cta-grid__tools__el--'] {
  position: relative;
  flex-basis: 100%;
  margin-bottom: 2rem;
  padding: 5rem math.div(100%, 20);

  &:last-child {
    margin-bottom: 0;
  }

  @include mq(m) {
    flex-shrink: 0;
    flex-basis: calc(50% - 1rem);
    margin-bottom: 0;
    padding: 9rem calc(100% / 20);

    &:nth-child(2n) {
      margin-left: 2rem;
    }

    &:nth-child(n + 3) {
      margin-top: 2rem;
    }

    &:nth-child(3) {
      padding: 4rem 20% 5.4rem calc(100% / 20);
    }
  }
}

[class*='cta-grid__tools__el--'][class*='--social'] {
  color: $c-blue;
  text-align: center;
  background: $c-white;

  @include mq(m) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    padding: 4rem calc(100% / 20) 5.4rem;
  }
}

.cta-grid__tools__el__title {
  /* stylelint-disable-next-line declaration-no-important */
  margin-top: 0 !important;
  margin-bottom: 1.5rem;
  padding: 0;

  strong {
    display: block;
  }

  @include mq(xs) {
    max-width: calc(100% - 18rem);

    [class*='cta-grid__tools__el--'][class*='--social'] & {
      max-width: none;
    }
  }

  @include mq(m) {
    max-width: 60%;
    margin-bottom: 3rem;

    [class*='--social'] & {
      max-width: none;
      margin: 0 auto 3rem;
    }

    [class*='cta-grid__tools__el--']:nth-child(3) & {
      max-width: none;
      margin-bottom: 2rem;
    }
  }

  @include mq(xl) {
    max-width: 70%;
    font-size: 3.6rem;
  }
}

.cta-grid__tools__el__headline {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 3.2rem;

  @include mq(m) {
    font-size: 1.3rem;
  }

  @include mq(xl) {
    font-size: 1.4rem;
  }
}

.cta-grid__tools__el__cta {
  h3 + & {
    margin-top: 0;
  }
}

.store-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 65%;
  margin: 0;
  padding: 0;
  list-style: none;

  @include mq(m) {
    width: 70%;
  }
}

.store-list__el__wrapper {
  transition: opacity 0.3s ease-out;

  &:hover {
    opacity: 0.8;
  }
}

.store-list__el {
  width: 10rem;

  img {
    width: 100%;
  }

  @include mq(m) {
    width: auto;
  }
}

.cta-grid__tools__ribbon {
  position: absolute;
  right: 2rem;
  bottom: 0;
  display: block;
  overflow: hidden;
  width: 20rem;
  height: 75%;
  border-top-right-radius: 50rem;
  border-top-left-radius: 50rem;

  img {
    @include image-fit(cover);
  }

  @include mq($until: xs) {
    display: none;
  }

  @include mq(m) {
    height: 60%;
  }

  @include mq(l) {
    height: 75%;
  }

  @include mq(xl) {
    [class*='cta-grid__tools__el--']:nth-child(3) & {
      top: 50%;
      right: 0;
      bottom: initial;
      width: 23.5rem;
      height: calc(100% - 8rem);
      border-top-right-radius: 0;
      border-top-left-radius: 50rem;
      border-bottom-left-radius: 50rem;
      transform: translateY(-50%);
    }
  }
}

.social-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

.social-list__el {
  position: relative;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $c-white;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.2s ease-out;
    content: '';
  }

  &:not(:last-child) {
    margin-right: 2rem;
  }

  :deep(a) {
    display: block;
    width: 100%;
    height: 100%;
    background-color: $c-cobalt;
    border-radius: 100%;
  }

  :deep(svg) {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2rem;
    height: 2rem;
    fill: $c-white;
    transform: translate(-50%, -50%);
    transition: fill 0.2s ease-out;
  }

  &:hover {
    &::before {
      opacity: 1;
    }

    :deep(svg) {
      fill: #ffae13;
    }
  }

  @include mq(l) {
    width: 6rem;
    height: 6rem;

    :deep(svg) {
      width: 2.8rem;
      height: 2.8rem;
    }
  }
}

.social-list__el__link {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
