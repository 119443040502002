<template>
  <section
    v-if="content.items?.length"
    ref="rootElRef"
    class="numbers wrapper p-xxxl"
  >
    <div class="numbers__title-outer">
      <h2
        class="numbers__title__title h1"
        :class="`title-${content.titleColor}`"
        v-html="content.title"
      ></h2>
      <small
        v-if="content.baseline"
        class="numbers__title__baseline mt-s"
        v-html="content.baseline"
      ></small>
    </div>

    <ul class="numbers__list">
      <li v-for="item in content.items" :key="item.text" class="numbers__item">
        <SvgSprite
          symbol="ui-check"
          size="48"
          role="img"
          class="numbers__item__icon"
        />
        <h3 class="numbers__item__title h2" v-html="item.number"></h3>
        <div class="numbers__item__text" v-html="item.text"></div>
      </li>
    </ul>
  </section>
</template>

<script lang="ts">
export interface Numbers {
  title: string
  titleColor?: string
  baseline?: string
  items: {
    number: string
    text: string
  }[]
}
</script>

<script setup lang="ts">
import gsap from 'gsap'
import { onMounted, ref } from 'vue'

import { onAppear } from '@/utils/motion'

import type { PropType } from 'vue'

defineProps({
  content: {
    type: Object as PropType<Numbers>,
    required: true,
  },
})

// Refs
const rootElRef = ref<HTMLElement | null>(null)

onMounted(() => {
  if (!rootElRef.value) {
    return
  }

  // Hide before appear
  gsap.set(rootElRef.value, { opacity: 0 })

  // Animate on appear
  onAppear(rootElRef.value)
})
</script>

<style lang="scss" scoped>
$bp: m;

.numbers {
  @include mq($bp) {
    display: flex;
    justify-content: space-between;
  }
}

.numbers__title-outer {
  @include mq($bp) {
    width: col(6, var(--available-columns));
  }
}

.numbers__title__title {
  @include section-title;
}

.numbers__title__baseline {
  color: $c-grey;
}

.numbers__list {
  @extend %list-nostyle;

  margin-top: $spacing * 1.5;

  @include mq($bp) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: col(10, var(--available-columns));
    margin: 0 0 0 col(1, var(--available-columns));
    gap: $spacing * 2 0;
  }

  @include mq(xxl) {
    gap: $spacing * 2 0;
  }
}

.numbers__item {
  display: grid;
  grid-template-rows: min-content 1fr;
  grid-template-columns: min-content 1fr;
  align-items: center;

  & + & {
    margin-top: $spacing * 0.75;
  }

  @include mq($bp) {
    width: col(4.5, 10);

    & + & {
      margin-top: 0;
    }
  }
}

.numbers__item__icon {
  width: 4.8rem;
  height: 4.8rem;
  margin-right: $spacing * 0.25;
  fill: $c-olive;

  @include mq($bp) {
    width: 6.4rem;
    height: 6.4rem;
    margin-right: $spacing * 0.5;
  }
}

.numbers__item__title {
  grid-column: 2/3;
}

.numbers__item__text {
  grid-column: 1/3;
  align-self: self-start;

  @include mq($bp) {
    margin-top: $spacing * 0.5;
  }
}
</style>
