<template>
  <div class="ui-loader">
    <!-- <div class="ui-loader__inner"></div> -->
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.ui-loader {
  --loader-size: 4rem;
  --loader-primary-color: #{$c-grey-light};
  --loader-secondary-color: #{$c-red};
  --loader-thickness: calc(var(--loader-size) * 0.2);

  position: relative;
  width: var(--loader-size);
  height: var(--loader-size);
  border: var(--loader-thickness) solid var(--loader-primary-color);
  border-bottom-color: var(--loader-secondary-color);
  border-radius: 50%;
  animation: loader 1s linear infinite;
  transform-origin: center;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
