<template>
  <div>
    <h1>Resources</h1>
    <AccordionGroup>
      <Accordion :items="[{ title: 'Recommended IDE Setup' }]">
        <template #content>
          <ul>
            <li>
              <a href="https://code.visualstudio.com/">VSCode</a>
              <ul>
                <li>
                  <a
                    href="https://marketplace.visualstudio.com/items?itemName=johnsoncodehk.volar"
                    >Volar</a
                  >
                </li>
                <li>
                  <a
                    href="https://marketplace.visualstudio.com/items?itemName=EditorConfig.EditorConfig"
                    >EditorConfig for Visual Studio Code</a
                  >
                </li>
                <li>
                  <a
                    href="https://marketplace.visualstudio.com/items?itemName=dbaeumer.vscode-eslint"
                    >ESLint</a
                  >
                </li>
                <li>
                  <a
                    href="https://marketplace.visualstudio.com/items?itemName=DavidAnson.vscode-markdownlint"
                    >markdownlint</a
                  >
                </li>
                <li>
                  <a
                    href="https://marketplace.visualstudio.com/items?itemName=esbenp.prettier-vscode"
                    >Prettier - Code formatter</a
                  >
                </li>
                <li>
                  <a
                    href="https://marketplace.visualstudio.com/items?itemName=stylelint.vscode-stylelint"
                    >Stylelint</a
                  >
                </li>
              </ul>
            </li>
          </ul>
        </template>
      </Accordion>
      <Accordion :items="[{ title: 'Stack' }]">
        <template #content>
          <ul>
            <li>
              <a href="https://v3.vuejs.org/">Vue 3</a>
              <ul>
                <li><a href="https://next.router.vuejs.org/">Vue Router</a></li>
                <li><a href="https://pinia.esm.dev/">Pinia</a></li>
                <li><a href="https://vue-i18n.intlify.dev/">Vue I18n</a></li>
                <li><a href="https://vueuse.org/">@vueuse</a></li>
                <li>
                  <a href="https://github.com/vueuse/head#readme"
                    >@vueuse/head</a
                  >
                </li>
                <li>
                  <a
                    href="https://github.com/thierrymichel/vue-svg-sprite#readme"
                    >vue-svg-sprite</a
                  >
                </li>
              </ul>
            </li>
            <li><a href="https://www.typescriptlang.org/">TypeScript</a></li>
            <li><a href="https://sass-lang.com/">Sass</a></li>
            <li>
              <a href="https://vitejs.dev/">Vite</a>
              <ul>
                <!-- <li>
                  <a
                    href="https://github.com/stafyniaksacha/vite-plugin-radar#readme"
                    >vite-plugin-radar</a
                  >
                </li> -->
                <li>
                  <a
                    href="https://github.com/anncwb/vite-plugin-svg-icons/tree/main#readme"
                    >vite-plugin-svg-icons</a
                  >
                </li>
                <li>
                  <a href="https://github.com/frandiox/vite-ssr">Vite SSR</a>
                </li>
              </ul>
            </li>
            <li><a href="https://greensock.com/gsap/">GSAP3</a></li>
            <li>
              <a
                href="https://github.com/andy-piccalilli/modern-css-reset#readme"
                >A modern CSS reset</a
              >
            </li>
            <li><a href="https://axios-http.com/">Axios</a></li>
          </ul>
        </template>
      </Accordion>
    </AccordionGroup>
    <hr />
    <Accordion :items="[{ title: 'debug' }]">
      <template #content>
        <pre>PROPS: {{ content.title }}</pre>
        <pre>INJECT: {{ injectContent.title }}</pre>
        <pre>STORE: {{ resource.content.title }}</pre>
        <div>
          <em
            ><small
              >with SSR, printing the whole store is not possible…(JSON circular
              error…)</small
            ></em
          >
        </div>
        <!-- <ClientOnly>
          <pre>STORE: {{ resource }}</pre>
        </ClientOnly> -->
      </template>
    </Accordion>
  </div>
</template>

<script setup lang="ts">
import { inject, ref } from 'vue'

import Accordion from '@/components/ui/Accordion.vue'
import AccordionGroup from '@/components/ui/AccordionGroup.vue'
import { contentDefault, ContentKey } from '@/core/resource'
import { useResourceStore } from '@/stores/resource'

defineProps({
  content: {
    type: Object,
    required: true,
  },
})

const injectContent = inject(ContentKey, ref(contentDefault))
const resource = useResourceStore()
</script>
