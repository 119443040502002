<template>
  <div ref="container" class="solutions-grid wrapper p-xl">
    <h2
      ref="title"
      data-animate
      class="solutions-grid__title"
      v-html="content.title"
    ></h2>
  </div>

  <slot name="component"></slot>

  <div class="wrapper p-xl">
    <!-- <div
      v-if="content && content.featured"
      ref="featured"
      class="solutions-grid__featured"
    > -->
    <!-- <div
        ref="featuredBgColor"
        class="solutions-grid__featured__bgcolor"
        :class="content.featured?.background_color"
      ></div> -->
    <!-- <div
        class="solutions-grid__featured__bg"
        [style.background-image]="'url('+content.featured.background_picture.src+')'"
        ref="featuredBgImage"
      ></div>
      <div class="solutions-grid__featured__inner">
        <div
          v-if="content.featured.card_picture"
          class="solutions-grid__featured__img-outer"
        >
          <img
            ref="cardPicture"
            class="solutions-grid__featured__img"
            :src="content.featured.card_picture.src"
            alt="{{content.featured.card_picture.alt}}"
          />
        </div>
        <div ref="featuredText" class="solutions-grid__featured__ctn">
          <h3
            class="solutions-grid__featured__title"
            v-html="content.featured.title"
          ></h3>
          <p
            class="solutions-grid__featured__text"
            v-html="content.featured.text"
          ></p>
          <ul>
            <li v-if="content.featured.links[0]">
              <ed-link
                [link]="content.featured.links[0]"
                css="btn cta"
              ></ed-link>
            </li>
            <li v-if="content.featured.links[1]">
              <ed-link
                [link]="content.featured.links[1]"
                css="btn last bordered white"
              ></ed-link>
            </li>
          </ul>
        </div>
      </div> -->
    <!-- </div> -->

    <div class="solutions-grid">
      <div class="solutions-grid__inner">
        <ul ref="gridctn" class="solutions-grid__list">
          <li
            v-for="(card, index) in content.cards"
            :key="index"
            ref="gridElement"
            data-animate
            class="solutions-grid__list__el card-element"
          >
            <div class="card-element__inner">
              <div class="card-element__wrapper">
                <img
                  v-if="card.picture"
                  v-src="card.picture"
                  :alt="card.picture.alt"
                  :sets="['320', '480', '640']"
                />
              </div>
              <div class="card-element__content">
                <div class="card-element__content__bg"></div>
                <h4 v-if="card.title" v-html="card.title"></h4>
                <div v-if="card.subtitle" v-html="card.subtitle"></div>
                <div
                  v-if="card.text"
                  class="card-element__content__text"
                  v-html="card.text"
                ></div>
                <div class="card-element__content__links">
                  <Action
                    v-if="card.employeesLink"
                    :content="{
                      ...card.employeesLink,
                      modifiers: ['btn'],
                    }"
                    class="focus__links__item"
                  />
                  <Action
                    v-if="card.merchantLink"
                    :content="{
                      ...card.merchantLink,
                      modifiers: ['btn', 'black'],
                    }"
                    class="focus__links__item"
                  />
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap'
import { onMounted, ref } from 'vue'

import { onAppear } from '@/utils/motion'

import type { PropType } from 'vue'
import type { Picture, Uri } from '@/types'

export interface SolutionGrid {
  title: string
  cards: {
    background_color: string
    employeesLink: Uri
    merchantLink: Uri
    logo: string
    picture: Picture
    title: string
    subtitle: string
    text: string
  }[]
}

const gridctn = ref<HTMLElement | null>(null)
const container = ref<HTMLElement | null>(null)
const title = ref<HTMLElement | null>(null)
// const featured = ref<HTMLElement | null>(null)
// const featuredBgColor = ref<HTMLElement | null>(null)
const gridElement = ref<HTMLElement | null>(null)

defineProps({
  content: {
    type: Object as PropType<SolutionGrid>,
    required: true,
  },
})

const appear = () => {
  if (!container.value) {
    return
  }

  const tl = gsap.timeline({
    onComplete: () => {
      gsap.set(animatedEls, { clearProps: 'all' })
    },
  })

  tl.set(container.value, { clearProps: 'opacity' })

  const animatedEls: Element[] = [container.value]

  if (gridctn.value) {
    const contentChildrenEl = container.value.querySelectorAll('[data-animate]')

    animatedEls.push(...contentChildrenEl)

    tl.fromTo(
      contentChildrenEl,
      {
        ease: 'power3.inOut',
        y: -40,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 0.3,
        stagger: 0.1,
      },
      0.6
    )
  }
}

onMounted(() => {
  gsap.set(container.value, { opacity: 0, ease: 'power3.inOut' })

  onAppear(
    container.value,
    () => {
      appear()
    },
    {
      once: true,
      rootMargin: '0px 0px -20% 0px',
      waitForHero: false,
    }
  )
})
</script>

<style lang="scss" scoped>
.solutions-grid {
  position: relative;

  .solutions-grid__title + &,
  .solutions-grid__featured + & {
    margin-top: 3rem;
  }

  @include mq(m) {
    .solutions-grid__title + &,
    .solutions-grid__featured + & {
      margin-top: 5rem;
    }
  }
}

.solutions-grid__title {
  margin: 0 auto;
  text-align: center;
}

.solutions-grid__featured {
  position: relative;
  width: 100%;
  margin-top: 4rem;
  padding: 8rem 0;
  background-repeat: no-repeat;
  background-size: cover;

  .solutions-grid__title + & {
    margin-top: 3rem;
  }

  @include mq(m) {
    .solutions-grid__title + & {
      margin-top: 5rem;
    }
  }

  @include mq(xl) {
    padding: 8rem calc((100vw - #{$csg-width}) / 2);
  }
}

.solutions-grid__featured__bgcolor {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.solutions-grid__featured__bg {
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  width: 25%;
  height: 100%;
  background-size: cover;

  @include mq(xl) {
    display: block;
  }
}

.solutions-grid__featured__inner {
  position: relative;
  padding: 0 calc(100% / 20);

  @include mq(640px) {
    padding: 0 calc((100% / 20) * 3);
  }

  @include mq(760px) {
    display: flex;
    justify-content: space-between;
  }
}

.solutions-grid__featured__img-outer {
  width: 60%;
  margin: 0 auto;

  @include mq(760px) {
    flex-basis: 35%;
    width: 35%;
    margin: 0;
  }
}

.solutions-grid__featured__img {
  display: block;
  width: 100%;
  height: 100%;
  transform: rotate(-20deg);

  @include mq(760px) {
    object-fit: contain;
  }
}

.solutions-grid__featured__ctn {
  ul {
    display: flex;
    margin: 0;
    margin-top: 3.6rem;
    padding: 0;
    list-style: none;

    @include mq(m) {
      display: flex;
    }

    @include mq(xl) {
      display: flex;
    }
  }
}

.solutions-grid__featured__title {
  margin: 0;
  margin-top: 5rem;
}

.solutions-grid__featured__text {
  font-weight: 400;
}

.card-element__content {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  width: 100%;
  min-height: 100%;
  padding: 4rem;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease-out;

  :deep(p) {
    font-size: 1.3rem;
    line-height: 2rem;

    @include mq(m) {
      width: 110%;
      font-size: 1.4rem;
      line-height: 2.2rem;
    }

    @include mq(xl) {
      font-size: 1.5rem;
      line-height: 2.4rem;
    }
  }

  :deep(svg) {
    display: block;
    width: 50%;
    fill: $c-white;
  }

  div {
    color: $c-black;
    font-size: 1.4rem;
    line-height: 2.4rem;

    @include mq(m) {
      font-size: 1.5rem;
      line-height: 2.5rem;
    }
  }

  h4 {
    margin-top: 0;
  }
}

// List
.solutions-grid__list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  padding: 0;
  list-style: none;

  @include mq(s) {
    gap: 2rem;
  }
}

.card-element {
  position: relative;
  flex-basis: 100%;

  &::after {
    content: '';
    display: block;
    padding-top: 72.36%;
  }

  @include mq(s) {
    flex-basis: calc(100% / 2 - 1.4rem);
  }

  @include mq(l) {
    flex-basis: calc(100% / 3 - 1.4rem);
  }
}

.card-element__inner {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  &:hover .card-element__content {
    opacity: 1;
    pointer-events: initial;

    :deep(.card-element__content__bg) {
      transform: scale(1.1);
    }
  }
}

.card-element__wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 2rem;

  img {
    @include image-fit(cover);

    display: block;
    width: 100%;
    height: 100%;
  }
}

.card-element__content__bg {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(1);
  background-color: $c-blue-light;
  border-radius: 2rem;
  transition: transform 0.3s ease-out;
}

.card-element__content__text {
  @include xs-space;
}

.card-element__content__links {
  display: flex;
  gap: 1rem;
  margin-top: 3rem;

  :deep(.btn) {
    font-size: 1.5rem;
  }

  :deep(.btn.last) {
    margin-right: 0;
  }
}
</style>
