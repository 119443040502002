<template>
  <pre>CSS v-bind()</pre>
  <SvgSprite
    class="epic-logo"
    symbol="epic-logo"
    size="48"
    aria-hidden="true"
  />
</template>

<script setup lang="ts">
import { ref } from 'vue'

// https://github.com/vuejs/rfcs/pull/231
const fill = ref('#BC994E')
const duration = 2

setInterval(() => {
  fill.value = `#${Math.floor(Math.random() * 16777215).toString(16)}`
}, duration * 1000)
</script>

<style scoped>
.epic-logo {
  margin-left: 2rem;
  transition: fill v-bind(duration + 's');
  fill: v-bind(fill);
}
</style>
